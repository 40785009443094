import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { TEAM_INVITE_MEMBER, TEAM_LIST_ROLES } from "./actionType";
import { teamResponseSuccess, teamResponseError } from "./action";

import { getRoles, inviteMember } from "../../helpers/backend_helper";

function* listRoles() {
  try {
    const response = yield call(getRoles);
    if (response.status === 'success') {
      yield put(teamResponseSuccess(TEAM_LIST_ROLES, response.data));
    } else {
      yield put(teamResponseError(TEAM_LIST_ROLES, response.msg));
    }
  } catch (error) {
    yield put(teamResponseError(TEAM_LIST_ROLES, error.message));
  }
}

function* invite({ payload: { data } }) {
  try {
    const response = yield call(inviteMember, data);
    if (response.status === 'success') {
      yield put(teamResponseSuccess(TEAM_INVITE_MEMBER));
    } else {
      yield put(teamResponseError(TEAM_INVITE_MEMBER, response.msg));
    }
  } catch (error) {
    yield put(teamResponseError(TEAM_INVITE_MEMBER, error.message));
  }
}

export function* watchListRoles() {
  yield takeEvery(TEAM_LIST_ROLES, listRoles);
}
export function* watchInvite() {
  yield takeEvery(TEAM_INVITE_MEMBER, invite);
}

function* teamSaga() {
  yield all([fork(watchListRoles)]);
  yield all([fork(watchInvite)]);
}

export default teamSaga;
