import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_TYPE } from "../helpers/consts";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBaseUi, setIsBaseUi] = useState(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);

    // Pages
    const [isLanding, setIsLanding] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState === 'Widgets') {
            history("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState !== 'Landing') {
            setIsLanding(false);
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isApps,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel
    ]);

    const { account, isResolving } = useSelector(state => ({
        account: state.Account.account,
        isResolving: state.Account.isResolving,
    }));

    let menuItems = [];
    if (account?.type === ACCOUNT_TYPE.VENDOR) {
        menuItems = [
            {
                id: "dashboard",
                label: "Dashboard",
                icon: "ri-apps-2-line",
                link: "/dashboard",
                click: function (e) {
                    e.preventDefault();
                    setIscurrentState('Dashboard');
                }
            },
            {
                id: "apis",
                label: "APIs & Services",
                icon: "ri-stack-line",
                link: "/apis",
                click: function (e) {
                    e.preventDefault();
                    setIscurrentState('APIs');
                }
            },
            {
                id: "accounts",
                label: "Accounts",
                icon: "las la-users-cog", //las la-users
                link: "/accounts",
                click: function (e) {
                    e.preventDefault();
                    setIscurrentState('Accounts');
                }
            },
            {
                id: "iam-admin",
                label: "IAM & Admin",
                icon: "ri-shield-user-line",
                link: "/iam-admin",
                click: function (e) {
                    e.preventDefault();
                    setIscurrentState('IAM & Admin');
                }
            },
        ];
    } else if (account?.type === ACCOUNT_TYPE.CUSTOMER) {
        menuItems = [
            {
                id: "dashboard",
                label: "Dashboard",
                icon: "ri-apps-2-line",
                link: "/dashboard",
                click: function (e) {
                    e.preventDefault();
                    setIscurrentState('Dashboard');
                }
            },
            {
                id: "apis",
                label: "APIs & Services",
                icon: "ri-stack-line",
                link: "/apis",
                click: function (e) {
                    e.preventDefault();
                    setIscurrentState('APIs');
                }
            },
        ];
    }

    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;