import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Nav, NavItem, Button, Form } from 'reactstrap'
import { Link } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import { useSelector, useDispatch } from 'react-redux'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useFormik } from 'formik'
import { getExams, getSchools, getSubjects, getTests, getYears, saveSelections } from '../../../../store/actions'

const PQApi = () => {

  document.title = 'PQ API Overview | Developer Console';

  const ref = useRef();
  const dispatch = useDispatch();

  const [categories, setCategories] = useState(['Exams', 'Subjects', 'Schools', 'Years', 'Tests']);
  const [available, setAvailable] = useState({
    exams: [], subjects: [], schools: [], years: [], tests: []
  });
  const [enabled, setEnabled] = useState({ exams: [], subjects: [], schools: [], years: [], tests: [] });
  const [selectedCategory, setSelectedCategory] = useState('Exams');
  const [dragging, setDragging] = useState(null);
  const [timestamp, setTimestamp] = useState(null);

  const { exams, subjects, schools, years, tests, isSaving } = useSelector((state) => ({
    exams: state.PQ.exams,
    subjects: state.PQ.subjects,
    schools: state.PQ.schools,
    years: state.PQ.years,
    tests: state.PQ.tests,
    isSaving: state.PQ.isSaving,
  }));

  useEffect(() => {
    setTimestamp(new Date());
  }, [available?.exams?.length, available?.subjects?.length, available?.schools?.length, available?.years?.length, available?.tests?.length]);

  useEffect(() => {
    setAvailable({ ...available, exams });
    setEnabled({ ...enabled, exams: exams?.filter(i => i?.userId) });
    console.log('exams');
  }, [exams]);
  useEffect(() => {
    setAvailable({ ...available, subjects });
    setEnabled({ ...enabled, subjects: subjects?.filter(i => i?.userId) });
    console.log('subjects');
  }, [subjects]);
  useEffect(() => {
    setAvailable({ ...available, schools });
    setEnabled({ ...enabled, schools: schools?.filter(i => i?.userId) });
    console.log('schools');
  }, [schools]);
  useEffect(() => {
    setAvailable({ ...available, years });
    setEnabled({ ...enabled, years: years?.filter(i => i?.userId) });
    console.log('years');
  }, [years]);
  useEffect(() => {
    setAvailable({ ...available, tests });
    setEnabled({ ...enabled, tests: tests?.filter(i => i?.userId) });
    console.log('tests');
  }, [tests]);

  useEffect(() => {
    dispatch(getExams({ enabled: true }));
    dispatch(getSubjects({ enabled: true }));
    dispatch(getSchools({ enabled: true }));
    dispatch(getYears({ enabled: true }));
    dispatch(getTests({ enabled: true }));
  }, [dispatch]);

  //serach api
  const searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue
    input = document.getElementById('search-user')
    filter = input.value.toUpperCase()
    var userList = document.getElementsByClassName('users-list')
    Array.prototype.forEach.call(userList, function (el) {
      li = el.getElementsByTagName('li')
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName('a')[0]
        txtValue = a.textContent || a.innerText
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = ''
        } else {
          li[i].style.display = 'none'
        }
      }
    })
  }

  const onDrag = (event, content) => {
    event.preventDefault();
    setDragging(content);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    // const draggedEl = event.draggedEl;
    // const draggedElclass = draggedEl.className;
    // if (
    //   draggedEl.classList.contains("external-event") &&
    //   draggedElclass.indexOf("fc-event-draggable") === -1
    // ) { handleApi(draggedEl) }
  }

  const onAdd = (event) => {
    const arr = enabled[selectedCategory.toLowerCase()];
    if (arr.filter(i => i._id === dragging._id).length > 0) return;
    arr.push(dragging);
    setEnabled({ ...enabled, [selectedCategory.toLowerCase()]: arr });
  };

  const onRemove = (event) => {
    const arr = enabled[selectedCategory.toLowerCase()];
    const newArr = arr.filter(i => i._id !== dragging._id);
    setEnabled({ ...enabled, [selectedCategory.toLowerCase()]: newArr });
  };

  const onSave = () => {
    const obj = {};
    for (const [key, value] of Object.entries(enabled)) {
      obj[key] = value.map(i => (i._id))
    }
    dispatch(saveSelections(obj));
  }

  return (
    <React.Fragment>
      <div className="page-content auth-bg-cover-2">
        <Container fluid>
          {/* <BreadCrumb title="PQ Api Overview" /> */}
          <Row>
            <Col lg={12}>
              <div className="">
                <ul className="list-inline categories-filter animation-nav" id="filter">

                  {categories.map((i, x) => <li key={`category-tab-${x}`} className="list-inline-item">
                    <Link
                      to="#"
                      onClick={() => setSelectedCategory(i)}
                      className={selectedCategory === i ? 'categories active' : 'categories'}
                      data-filter="*"
                    >{i}</Link>
                  </li>)}
                </ul>
              </div>
            </Col>

            <Col lg={6}>
              <div
                className="w-100 bg-white"
                style={{ marginRight: '1px' }}
                onDrop={onRemove}
                onDragOver={onDragOver}>
                <div className="px-4 pt-4 mb-3">
                  <div className="search-box">
                    <input
                      onKeyUp={searchUsers}
                      id="search-user"
                      type="text"
                      className="form-control bg-light border-light"
                      placeholder="Search here..."
                    />
                    <i className="ri-search-2-line search-icon"></i>
                  </div>
                </div>

                <div className="text-muted">
                  <div>
                    <SimpleBar
                      className="chat-room-list pt-3"
                      style={{ margin: '-16px 0px 0px' }}
                    >
                      <div className="d-flex align-items-center px-4 mb-2">
                        <div className="flex-grow-1">
                          <h4 className="mb-0 fs-11 text-muted text-uppercase">
                            Available {selectedCategory}
                          </h4>
                        </div>
                      </div>

                      <div className="chat-message-list">
                        <ul
                          className="list-unstyled chat-list chat-user-list users-list"
                          id="userList"
                        >
                          {available[selectedCategory.toLowerCase()].map((pq) => (
                            <li key={`available-${selectedCategory}-${pq._id}`}
                              draggable
                              onDrag={(event) => {
                                onDrag(event, pq);
                              }}>
                              <div className='external-event fc-event text-black'>{pq.name}</div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </SimpleBar>
                  </div>
                </div>
              </div>{' '}
            </Col>
            <Col lg={6}>
              <div className="w-100 bg-white"
                onDrop={onAdd}
                onDragOver={onDragOver}
              >
                <div className="px-4 pt-4 mb-3">
                  <div className="search-box">
                    <input
                      onKeyUp={searchUsers}
                      id="search-user"
                      type="text"
                      className="form-control bg-light border-light"
                      placeholder="Search here..."
                    />
                    <i className="ri-search-2-line search-icon"></i>
                  </div>
                </div>

                <div className="text-muted">
                  <div>
                    <SimpleBar
                      className="chat-room-list pt-3"
                      style={{ margin: '-16px 0px 0px' }}
                    >
                      <div className="d-flex align-items-center px-4 mb-2">
                        <div className="flex-grow-1">
                          <h4 className="mb-0 fs-11 text-muted text-uppercase">
                            Enabled {selectedCategory}
                          </h4>
                        </div>
                      </div>

                      <div className="chat-message-list">
                        {enabled[selectedCategory.toLowerCase()].length === 0 ? <div className='fs-13 text-center'>
                          Drag here to add {selectedCategory.toLowerCase()}
                        </div> :
                          <ul
                            className="list-unstyled chat-list chat-user-list users-list"
                            id="userList"
                          >
                            {enabled[selectedCategory.toLowerCase()].map((pq) => (
                              <li key={`enabled-${selectedCategory}-${pq._id}`}
                                draggable
                                onDrag={(event) => {
                                  onDrag(event, pq);
                                }}
                              >
                                <div className='external-event fc-event text-black'>{pq.name}</div>
                              </li>
                            ))}
                          </ul>}
                      </div>
                    </SimpleBar>
                  </div>
                </div>
                <div className="text-end px-4 pt-4">
                  <Button
                    disabled={isSaving}
                    onClick={onSave}
                    type="submit"
                    className="btn btn-dark btn-sm mb-3"
                  >
                    {isSaving ? 'Saving...' : 'Save'}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          {/* <div className="chat-wrapper d-lg-flex mx-n4 mt-n2 p-1">


          </div> */}
        </Container>
      </div>

      {/* <PersonalInfo
        show={isInfoDetails}
        onCloseClick={() => setIsInfoDetails(false)}
        currentuser={Chat_Box_Username}
        cuurentiseImg={Chat_Box_Image}
      /> */}
    </React.Fragment>
  )
}

export default PQApi
