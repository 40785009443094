import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const PQItemActions = ({ item, type, title, id, onEdit, onDelete }) => {

  const { account } = useSelector(state => ({
    account: state.Account.account,
  }));

  const { permission: { products: { pq } } } = account;
  console.log('products here', pq);
  console.log('products type', account?.permission?.products?.pq?.[type]?.indexOf('delete'));

  return (
    <div className="ms-4 d-hover-item position-absolute bg-white end-0">
      <ul className="list-inline tasks-list-menu mx-3 mb-0">
        {account?.permission?.products?.pq?.[type]?.indexOf('view') > 0 && <li className="list-inline-item">
          <Link to="/apps-tasks-details">
            <i className="fs-16 ri-eye-fill align-bottom me-2 text-muted"></i>
          </Link>
        </li>}
        {account?.permission?.products?.pq?.[type]?.indexOf('edit') > 0 && <li className="list-inline-item">
          <Link to="#" onClick={() => onEdit(item)}>
            <i className="fs-16 ri-pencil-fill align-bottom me-2 text-muted"></i>
          </Link>
        </li>}
        {account?.permission?.products?.pq?.[type]?.indexOf('delete') > 0 && <li className="list-inline-item">
          <Link to="#" onClick={() => onDelete({ type, name: title, id })}>
            <i className="fs-16 ri-delete-bin-fill align-bottom me-2 text-muted"></i>
          </Link>
        </li>}
      </ul>
    </div>
  );
};

PQItemActions.propTypes = {
  item: PropTypes.object,
  type: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export default PQItemActions;