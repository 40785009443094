import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import samplePdf from '../../../../assets/sample.pdf'
import { useDispatch, useSelector } from 'react-redux';
import { ApiEnable, listApis } from '../../../../store/actions';

const APIs = () => {

    document.title = "APIs & Services | Developer Console";

    const dispatch = useDispatch();
    const [modal_scroll, setmodal_scroll] = useState(false);
    const [clickedApi, setClickedApi] = useState(null);

    const { list, apiEnabled } = useSelector((state) => ({
        list: state.Apis.list,
        apiEnabled: state.Apis.apiEnabled,
    }));

    useEffect(() => {
        dispatch(listApis());
    }, [])

    useEffect(() => {
        if (apiEnabled) {
            dispatch(listApis());
        }
    }, [apiEnabled])

    function toggle_scroll() {
        setmodal_scroll(!modal_scroll);
    }

    const enableApi = () => {
        if (!clickedApi) return;
        dispatch(ApiEnable({ apiId: clickedApi }));
        setmodal_scroll(false);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        {list.map((item, index) => <Col lg={4} xl={3} key={`api-${index}`}>
                            <Card className="card-body py-4 text-center shadow-none">
                                <i className="ri-apps-2-line ri-xl my-3 text-info fw-bold"></i>
                                <h5 className="card-title mb-1 fw-bold">{item.name}</h5>
                                <p className="text-muted mb-0">{item.shortDesc}</p>
                                {
                                    item?.status === 'approved' ?
                                        <Link to="/apis/pq" className='btn btn-soft-dark btn-sm mt-5 mb-2'>Overview</Link> : (
                                            item?.status === 'pending' ? <Link className='disabled btn btn-soft-dark btn-sm mt-5 mb-2'>Pending Approval</Link> :
                                                <Link onClick={() => {
                                                    setClickedApi(item._id);
                                                    setmodal_scroll(true);
                                                }} className='btn btn-soft-dark btn-sm mt-5 mb-2'>Enable</Link>
                                        )
                                }
                            </Card>
                        </Col>)}
                    </Row>

                    <Modal
                        onClosed={() => setClickedApi(null)}
                        isOpen={modal_scroll}
                        toggle={toggle_scroll}
                        scrollable={true}
                        id="exampleModalScrollable"
                    >
                        <ModalHeader className="bg-light p-3" toggle={toggle_scroll}>
                            Terms and Conditions!
                        </ModalHeader>
                        <div className="mx-1">
                            <ModalBody>
                                <p className="text-danger">Attention!!!</p>
                                <p>
                                    It is mandatory that the terms and conditions for using our
                                    APIs be properly read, signed and uploaded before access
                                    would be given. Use the button below to download the terms and
                                    conditions.
                                </p>
                                <p>
                                    You can submit the signed terms and document to: <span className='text-success'>api@roware.xyz</span>
                                </p>
                            </ModalBody>
                            <div className="modal-footer">
                                <div className="hstack gap-2 justify-content-end">
                                    <button
                                        type="button"
                                        className="btn btn-light btn-sm"
                                        onClick={() => setmodal_scroll(false)}
                                    >
                                        Close
                                    </button>

                                    <a href={samplePdf} download className="btn btn-dark btn-sm" onClick={enableApi}>
                                        Download
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default APIs;