import {
  APIS_LIST,
  APIS_LIST_SUCCESSFUL,
  APIS_LIST_FAILED,
  API_ENABLE
} from "./actionTypes"

export const listApis = () => {
  return { type: APIS_LIST }
}

export const ApiEnable = (data) => {
  return { type: API_ENABLE, payload: { data } }
}

export const listApisSuccessful = (actionType, data) => {
  return { type: APIS_LIST_SUCCESSFUL, payload: { actionType, data } }
}

export const listApisFailed = err => {
  return { type: APIS_LIST_FAILED, payload: err }
}
