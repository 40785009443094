import React, { useCallback, useState } from 'react'
import {
    Card,
    CardBody,
    Col,
    Container,
    CardHeader,
    Row,
    Input,
    Label,
    FormFeedback,
    Form,
    Button,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// import { registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Profile = (props) => {

    document.title = 'Account | Developer Console';

    const { account, isResolving, error } = useSelector(state => ({
        account: state.Account.account,
        isResolving: state.Account.isResolving,
        error: state.Account.error,
    }));

    const [isEdit, setIsEdit] = useState(false);
    const [profile, setProfile] = useState(account);
    const [isCopy, setIsCopy] = useState(false);
    const [isTestCopy, setIsTestCopy] = useState(false);

    const dispatch = useDispatch()

    const handleAccountClick = useCallback((arg) => {
        const account = arg
        setProfile({
            // _id: account._id,
            firstName: profile.firstName,
            lastName: profile.lastName,
            email: profile.email,
            password: profile.password,
            testKey: profile.testKey,
            liveKey: profile.liveKey,
        })

        setIsEdit(true)
    }, [])

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            firstName: profile?.firstName ?? '',
            lastName: profile?.lastName ?? '',
            email: profile?.email ?? '',
            organizationName: profile?.organizationName ?? '',
            password: profile?.password ?? '',
            testKey: profile?.testKey ?? '',
            liveKey: profile?.liveKey ?? '',
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('Please Enter First Name'),
            lastName: Yup.string().required('Please Enter Last Name'),
            email: Yup.string().required('Please Enter a Valid Email Address'),
            password: Yup.string().required('Please Enter Password'),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateAccount = {
                    // _id: account ? account._id : 0,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    password: values.password,
                }
                // update account
                // dispatch(onUpdateAccount(updateAccount));
                validation.resetForm()
            } else {
                const newAccount = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    password: values.password,
                }
            }
            // save new account
            // dispatch(onAddNewAccount(newAccount))
            validation.resetForm()
        },
    });

    const successnotify = () =>
        toast('Copied successfully!', {
            position: 'top-right',
            hideProgressBar: true,
            closeOnClick: false,
            className: 'bg-success text-white text-left w-75',
        });

    const onResetKey = () => {
        alert();
    }

    return (
        <div className="">
            <ToastContainer />
            <Container fluid>
                <Row>
                    <Col lg={8}>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                        >
                            <Card className='shadow-none'>
                                <CardBody>
                                    <div className="d-flex mb-3 fs-11">
                                        <i className="ri-user-fill ri-xl align-bottom me-2 mt-1"></i>
                                        <h5 className='fw-normal fs-14'>Account Details</h5>
                                    </div>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-normal p-0 m-0 mb-1" htmlFor="firstName">
                                                    First name
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter First Name"
                                                    name="firstName"
                                                    value={validation.values.firstName || ''}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    invalid={
                                                        validation.errors.firstName &&
                                                            validation.touched.firstName
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.errors.firstName &&
                                                    validation.touched.firstName ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.firstName}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-normal p-0 m-0 mb-1" htmlFor="lastName">
                                                    Last name
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Last Name"
                                                    name="lastName"
                                                    value={validation.values.lastName || ''}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    invalid={
                                                        validation.errors.lastName &&
                                                            validation.touched.lastName
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.errors.lastName &&
                                                    validation.touched.lastName ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.lastName}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label
                                                    className="form-label fw-normal p-0 m-0 mb-1"
                                                    htmlFor="email"
                                                >
                                                    Email
                                                </Label>
                                                <Input
                                                    disabled
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Enter Email"
                                                    name="email"
                                                    value={validation.values.email || ''}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    invalid={
                                                        validation.errors.email && validation.touched.email
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.errors.email && validation.touched.email ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.email}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label
                                                    className="form-label fw-normal p-0 m-0 mb-1"
                                                    htmlFor="organizationName"
                                                >
                                                    Organization Name
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Organization Name"
                                                    name="organizationName"
                                                    value={validation.values.organizationName || ''}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    invalid={
                                                        validation.errors.organizationName &&
                                                            validation.touched.organizationName
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.errors.organizationName &&
                                                    validation.touched.organizationName ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.organizationName}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <div className="text-end">
                                <Button type="submit" className="btn btn-dark btn-sm">
                                    {isEdit ? 'Update' : 'Save'}
                                </Button>
                            </div>
                        </Form>
                        <Form
                            className='mt-3'
                            onSubmit={(e) => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                        >
                            <Card className='shadow-none'>
                                <CardHeader>
                                    <h5 className="text-semibold text-black">Password</h5>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-normal p-0 m-0 mb-1" htmlFor="currentPassword">
                                                    Current Password
                                                </Label>
                                                <Input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Enter Current Password"
                                                    name="currentPassword"
                                                    value={validation.values.password || ''}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    invalid={
                                                        validation.errors.password &&
                                                            validation.touched.password
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.errors.password &&
                                                    validation.touched.password ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.password}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col lg={6}></Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-normal p-0 m-0 mb-1" htmlFor="product-title-input">
                                                    New Password
                                                </Label>
                                                <Input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Enter New Password"
                                                    name="newPassword"
                                                    value={validation.values.newPassword || ''}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    invalid={
                                                        validation.errors.newPassword &&
                                                            validation.touched.newPassword
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.errors.newPassword &&
                                                    validation.touched.newPassword ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.newPassword}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label className="form-label fw-normal p-0 m-0 mb-1" htmlFor="product-title-input">
                                                    Confirm Password
                                                </Label>
                                                <Input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Confirm New Password"
                                                    name="confirmPassword"
                                                    value={validation.values.confirmPassword || ''}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    invalid={
                                                        validation.errors.confirmPassword &&
                                                            validation.touched.confirmPassword
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.errors.confirmPassword &&
                                                    validation.touched.confirmPassword ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.confirmPassword}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <div className="text-end">
                                <Button type="submit" className="btn btn-dark btn-sm">
                                    {isEdit ? 'Update' : 'Change'}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Profile;