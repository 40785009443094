import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { APIS_LIST, API_ENABLE } from "./actionTypes";
import { listApisFailed, listApisSuccessful } from "./actions";
import { enableApi, getApis } from "../../helpers/backend_helper";

function* listApis() {
  try {
    const response = yield call(getApis);
    if (response.status === "success") {
      yield put(listApisSuccessful(APIS_LIST, response.data));
    } else {
      yield put(listApisFailed(response.msg));
    }
  } catch (error) {
    yield put(listApisFailed({ msg: error?.message ?? error.message }));
  }
}

function* ApiEnable({ payload: { data } }) {
  try {
    const response = yield call(enableApi, data);
    if (response.status === "success") {
      yield put(listApisSuccessful(API_ENABLE, response?.data));
    } else {
      yield put(listApisFailed(response.msg));
    }
  } catch (error) {
    yield put(listApisFailed({ msg: error?.message ?? error.message }));
  }
}

export function* watchListApis() {
  yield takeEvery(APIS_LIST, listApis);
}
export function* watchEnableApi() {
  yield takeEvery(API_ENABLE, ApiEnable);
}

function* apistSaga() {
  yield all([fork(watchListApis)]);
  yield all([fork(watchEnableApi)]);
}

export default apistSaga;
