import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, Spinner } from "reactstrap";

const DeleteModal = ({ show, onDeleteClick, onCloseClick, itemName, isDeleting }) => {
  return (
    <Modal fade={true} size="sm" isOpen={show} toggle={onCloseClick} centered={true} backdrop='static'>
      <ModalBody className="py-3 px-1">
        <div className="mt-1 text-center mb-4">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#f7b84b,secondary:#f06548"
            style={{ width: "100px", height: "100px" }}
          ></lord-icon>
          <div className="mt-1 pt-2 mx-4 mx-sm-5">
            <h4>Hey Stop!</h4>
            <p className="fs-16 text-muted mx-1 mb-0">{`Are you sure you want to delete `}<span className="fw-semibold">{itemName ?? 'record'}</span>?</p>
          </div>
        </div>
        <div className="hstack gap-2 justify-content-center">
          <Link to="#" className="btn btn-link link-success fw-medium" onClick={onCloseClick}>
            <i className="ri-close-line me-1 align-middle"></i> Cancel</Link>
          {isDeleting ? <Link to="#" className="btn btn-sm btn-danger">
            <span className="d-flex align-items-center">
              <Spinner size="sm" type="grow" className="flex-shrink-0" />
              <span className="flex-grow-1 ms-2">
                Deleting...
              </span>
            </span>
          </Link>
            : <Button color="danger" className="btn-load btn-sm" onClick={onDeleteClick}>
              Yes, Delete
            </Button>}
        </div>


        {/* <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            Close
          </button>
          <Button
            size="sm"
            className="btn-sm btn-danger"
            // id="delete-record"
            onClick={onDeleteClick}
          >
            Yes, Delete It!
          </Button>
        </div> */}
      </ModalBody>
    </Modal>
  );
};

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.bool,
  itemName: PropTypes.string,
};

export default DeleteModal;