import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Widgets from './Widgets';
import BalanceOverview from './BalanceOverview';
import DashboardCharts from './DashboardCharts';
import { crmWidgets } from "../../common/data";
import CountUp from "react-countup";
import { useSelector } from 'react-redux';

const apiWidgets = [
    {
        id: 1,
        label: "EXAMS",
        count: "3",
        percent: [15],
        color: "#09b39b",
    },
    {
        id: 2,
        label: "SUBJECTS",
        count: "2",
        percent: [7],
        color: "#09b39b",
    },
    {
        id: 3,
        label: "SCHOOLS",
        count: "1",
        percent: [15],
        color: "#09b39b",
    },
    {
        id: 4,
        label: "YEARS",
        count: "4",
        percent: [50],
        color: "#f06548",
    },
];

const Dashboard = () => {

    document.title = "Overview | Developer Console";

    const { account, isResolving, error } = useSelector(state => ({
        account: state.Account.account,
        isResolving: state.Account.isResolving,
        error: state.Account.error,
    }));

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <div className="col-xl-12">
                        <div className="d-flex flex-column h-100">
                            <div className='row'>
                                {apiWidgets.map((widget, key) => (
                                    <div className='col-xl-3 col-md-6' key={key}>
                                        <div className="card card-animate overflow-hidden shadow-none">
                                            <div
                                                className="position-absolute start-0"
                                                style={{ zIndex: "0" }}
                                            >
                                                <svg
                                                    version="1.2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 200 120"
                                                    width="200"
                                                    height="120"
                                                >
                                                    <path
                                                        id="Shape 8"
                                                        style={{ opacity: ".05", fill: "#0ab39c" }}
                                                        d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                                                    />
                                                </svg>
                                            </div>
                                            <div className='card-body' style={{ zIndex: "1" }}>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-grow-1 overflow-hidden">
                                                        <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                                            {" "}
                                                            {widget.label}
                                                        </p>
                                                        <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                                            <span className="counter-value" data-target="36894">
                                                                {widget.count}
                                                            </span>
                                                        </h4>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <DashboardCharts
                                                            seriesData={widget.percent}
                                                            colors={widget.color}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <BalanceOverview />

                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;