import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import {
  PQ_SET_EXAM,
  PQ_SET_QUESTION,
  PQ_SET_SCHOOL,
  PQ_SET_SUBJECT,
  PQ_SET_TEST,
  PQ_DELETE_ITEM,
  PQ_GET_EXAMS,
  PQ_GET_QUESTIONS,
  PQ_GET_SCHOOLS,
  PQ_GET_SUBJECTS,
  PQ_GET_TESTS,
  PQ_GET_YEARS,
  PQ_SAVE_SELECTIONS,
  PQ_SET_YEAR,
} from "./actionType";
import { pqApiSuccess, pqApiError } from "./action";

import {
  getExams,
  getQuestions,
  getSchools,
  getSubjects,
  getTests,
  getYears,
  pqDeleteItem,
  pqPostExam,
  pqPostQuestion,
  pqPostSchool,
  pqPostSubject,
  pqPostTest,
  pqPostYear,
  pqSave,
} from "../../helpers/backend_helper";

function* listExams({ payload: { data } }) {
  try {
    const response = yield call(getExams, data);
    if (response.status === "success") {
      yield put(pqApiSuccess(PQ_GET_EXAMS, response.data));
    } else {
      yield put(pqApiError(PQ_GET_EXAMS, response.msg));
    }
  } catch (error) {
    yield put(pqApiError(PQ_GET_EXAMS, error.message));
  }
}
function* listSubjects({ payload: { data } }) {
  try {
    const response = yield call(getSubjects, data);
    if (response.status === "success") {
      yield put(pqApiSuccess(PQ_GET_SUBJECTS, response.data));
    } else {
      yield put(pqApiError(PQ_GET_SUBJECTS, response.msg));
    }
  } catch (error) {
    yield put(pqApiError(PQ_GET_SUBJECTS, error.message));
  }
}
function* listSchools({ payload: { data } }) {
  try {
    const response = yield call(getSchools, data);
    if (response.status === "success") {
      yield put(pqApiSuccess(PQ_GET_SCHOOLS, response.data));
    } else {
      yield put(pqApiError(PQ_GET_SCHOOLS, response.msg));
    }
  } catch (error) {
    yield put(pqApiError(PQ_GET_SCHOOLS, error.message));
  }
}
function* listYears({ payload: { data } }) {
  try {
    const response = yield call(getYears, data);
    if (response.status === "success") {
      yield put(pqApiSuccess(PQ_GET_YEARS, response.data));
    } else {
      yield put(pqApiError(PQ_GET_YEARS, response.msg));
    }
  } catch (error) {
    yield put(pqApiError(PQ_GET_YEARS, error.message));
  }
}
function* listTests({ payload: { data } }) {
  try {
    const response = yield call(getTests, data);
    if (response.status === "success") {
      yield put(pqApiSuccess(PQ_GET_TESTS, response.data));
    } else {
      yield put(pqApiError(PQ_GET_TESTS, response.msg));
    }
  } catch (error) {
    yield put(pqApiError(PQ_GET_TESTS, error.message));
  }
}
function* listQuestions({ payload: { data } }) {
  try {
    const response = yield call(getQuestions, data);
    if (response.status === "success") {
      yield put(pqApiSuccess(PQ_GET_QUESTIONS, response.data));
    } else {
      yield put(pqApiError(PQ_GET_QUESTIONS, response.msg));
    }
  } catch (error) {
    yield put(pqApiError(PQ_GET_QUESTIONS, error.message));
  }
}
function* saveSelections({ payload: { data } }) {
  try {
    const response = yield call(pqSave, data);
    if (response.status === "success") {
      yield put(pqApiSuccess(PQ_SAVE_SELECTIONS, response.data));
    } else {
      yield put(pqApiError(PQ_SAVE_SELECTIONS, response.msg));
    }
  } catch (error) {
    yield put(pqApiError(PQ_SAVE_SELECTIONS, error.message));
  }
}
function* postExam({ payload: data }) {
  try {
    const response = yield call(pqPostExam, data);
    if (response.status === "success") {
      yield put(pqApiSuccess(PQ_SET_EXAM, response.data));
    } else {
      yield put(pqApiError(PQ_SET_EXAM, response.msg));
    }
  } catch (error) {
    yield put(pqApiError(PQ_SET_EXAM, error.message));
  }
}
function* postSubject({ payload: data }) {
  try {
    const response = yield call(pqPostSubject, data);
    if (response.status === "success") {
      yield put(pqApiSuccess(PQ_SET_SUBJECT, response.data));
    } else {
      yield put(pqApiError(PQ_SET_SUBJECT, response.msg));
    }
  } catch (error) {
    yield put(pqApiError(PQ_SET_SUBJECT, error.message));
  }
}
function* setSchool({ payload: data }) {
  try {
    const response = yield call(pqPostSchool, data);
    if (response.status === "success") {
      yield put(pqApiSuccess(PQ_SET_SCHOOL, response.data));
    } else {
      yield put(pqApiError(PQ_SET_SCHOOL, response.msg));
    }
  } catch (error) {
    yield put(pqApiError(PQ_SET_SCHOOL, error.message));
  }
}
function* setYear({ payload: data }) {
  try {
    const response = yield call(pqPostYear, data);
    if (response.status === "success") {
      yield put(pqApiSuccess(PQ_SET_YEAR, response.data));
    } else {
      yield put(pqApiError(PQ_SET_YEAR, response.msg));
    }
  } catch (error) {
    yield put(pqApiError(PQ_SET_YEAR, error.message));
  }
}
function* setTest({ payload: data }) {
  try {
    const response = yield call(pqPostTest, data);
    if (response.status === "success") {
      yield put(pqApiSuccess(PQ_SET_TEST, response.data));
    } else {
      yield put(pqApiError(PQ_SET_TEST, response.msg));
    }
  } catch (error) {
    yield put(pqApiError(PQ_SET_TEST, error.message));
  }
}
function* setQuestion({ payload: data }) {
  try {
    const response = yield call(pqPostQuestion, data);
    if (response.status === "success") {
      yield put(pqApiSuccess(PQ_SET_QUESTION, response.data));
    } else {
      yield put(pqApiError(PQ_SET_QUESTION, response.msg));
    }
  } catch (error) {
    yield put(pqApiError(PQ_SET_QUESTION, error.message));
  }
}
function* deleteItem({ payload: data }) {
  try {
    const response = yield call(pqDeleteItem, data);
    console.log('deleteItem', response);
    if (response.status === "success") {
      yield put(pqApiSuccess(PQ_DELETE_ITEM, response.data));
    } else {
      yield put(pqApiError(PQ_DELETE_ITEM, response.msg));
    }
  } catch (error) {
    yield put(pqApiError(PQ_DELETE_ITEM, error.message));
  }
}
export function* watchListExams() {
  yield takeEvery(PQ_GET_EXAMS, listExams);
}
export function* watchListSubjects() {
  yield takeEvery(PQ_GET_SUBJECTS, listSubjects);
}
export function* watchListSchools() {
  yield takeEvery(PQ_GET_SCHOOLS, listSchools);
}
export function* watchListYears() {
  yield takeEvery(PQ_GET_YEARS, listYears);
}
export function* watchListTests() {
  yield takeEvery(PQ_GET_TESTS, listTests);
}
export function* watchListQuestions() {
  yield takeEvery(PQ_GET_QUESTIONS, listQuestions);
}
export function* watchSave() {
  yield takeEvery(PQ_SAVE_SELECTIONS, saveSelections);
}
export function* watchSetExam() {
  yield takeEvery(PQ_SET_EXAM, postExam);
}
export function* watchSetSubject() {
  yield takeEvery(PQ_SET_SUBJECT, postSubject);
}
export function* watchSetSchool() {
  yield takeEvery(PQ_SET_SCHOOL, setSchool);
}
export function* watchSetYear() {
  yield takeEvery(PQ_SET_YEAR, setYear);
}
export function* watchSetTest() {
  yield takeEvery(PQ_SET_TEST, setTest);
}
export function* watchSetQuestion() {
  yield takeEvery(PQ_SET_QUESTION, setQuestion);
}
export function* watchDelete() {
  yield takeEvery(PQ_DELETE_ITEM, deleteItem);
}

function* pqSaga() {
  yield all([fork(watchListExams)]);
  yield all([fork(watchListSubjects)]);
  yield all([fork(watchListSchools)]);
  yield all([fork(watchListYears)]);
  yield all([fork(watchListTests)]);
  yield all([fork(watchListQuestions)]);
  yield all([fork(watchSave)]);
  yield all([fork(watchSetExam)]);
  yield all([fork(watchSetSubject)]);
  yield all([fork(watchSetSchool)]);
  yield all([fork(watchSetYear)]);
  yield all([fork(watchSetTest)]);
  yield all([fork(watchSetQuestion)]);
  yield all([fork(watchDelete)]);
}

export default pqSaga;
