import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import { FcGoogle } from "react-icons/fc";
import { FaGithub } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

import { accountSignUp } from "../../store/actions";

import heroBg from "../../assets/images/auth-one-bg.png"

const Register2 = () => {
  document.title = "Get Started | Developer Console";

  const history = useNavigate();
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      companyEmail: "",
      firstName: "",
      lastName: "",
      country: "",
      companyWebsite: "",
    },
    validationSchema: Yup.object({
      companyEmail: Yup.string().required("This field is required"),
      firstName: Yup.string().required("This field is required"),
      lastName: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      dispatch(accountSignUp(values, history));
    },
  });

  const { loading, error, registrationError, success } = useSelector((state) => ({
    registrationError: state.Account.registrationError,
    success: state.Account.success,
    loading: state.Account.loading,
    error: state.Account.error,
  }));

  // useEffect(() => {
  //     dispatch(apiError(""));
  // }, [dispatch]);

  useEffect(() => {
    if (success) {
      setTimeout(() => history("/login"), 3000);
    }

    // setTimeout(() => {
    //   dispatch(resetSignUpFlag());
    // }, 3000);
  }, [dispatch, success, error, history]);

  const disabled = validation.errors.companyEmail && validation.errors.firstName && validation.errors.lastName
  const invalidEmail = validation.values.companyEmail === ''
  const invalidFirstname = validation.values.firstName === ''
  const invalidLastname = validation.values.lastName === ''
  const invalid = (validation.values.companyEmail === '') && (validation.values.firstName === '') && (validation.values.lastName === '')

  const [navbar, setNavbar] = useState(false)

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  window.addEventListener('scroll', changeBackground)


  return (
    <React.Fragment>
      <div
        className="auth-page-wrapper overflow-hidden login-main-div"
      >
        <nav className={navbar ? 'auth__navbar pt-5 pb-5' : 'login__navbar pt-5 pb-5'}>
          <Link to='/' className="text-dark ps-5 pb-5">ROWARE LOGO</Link>
        </nav>
        <Row className="">
          <Col lg={6} md={12} className="py-5 my-5 reg-col1-query" style={{}}>
            <div className="mx-5 hero-img-div">
              <div className="mt-5 mx-5 hero__img-div-2">
                <img src={heroBg} alt="hero banner" className="hero__img" />
              </div>

              <div className="ms-5 login-col1-texts">
                <h4 className="text-center mt-3">Past Questions at your finger tips</h4>
                <p className="text-center mt-3">PQ API allows you to focus on developing your academic applications, platforms and solutions while we take care of the past and practice questions.</p>
                <Link to="" className="d-block text-center text-decoration-underline">Read more</Link>
              </div>
            </div>
          </Col>

          <Col lg={6} sm={12} className="reg-col2-query position-absolute" style={{ right: 0, }}>
            <Card className="shadow-none px-5 pb-5 pt-5 mb-0 login-col2-card" style={{ minHeight: '100vh' }}>
              <div className="mx-5 px-3 login-col2-div">
                <div className="login-col2-section">
                  <h3 className="login-col2-text text-warning mt-4">Sign up for free</h3>
                  <p className="text-muted">
                    Already have an account? <Link to="/login" className="text-decoration-underline">Login</Link>
                  </p>
                </div>
                {error ? <Alert color="danger"> {error} </Alert> : null}
                <div className="login-col2-form mt-5">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    action="#"
                  >
                    <div className="mb-4">
                      <Label
                        htmlFor="companyEmail"
                        className="form-label fw-normal p-0 m-0 mb-1"
                      >
                        Company email
                      </Label>
                      <Input
                        name="companyEmail"
                        className="form-control"
                        placeholder="Company email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.companyEmail || ""}
                        invalid={
                          validation.touched.companyEmail &&
                            validation.errors.companyEmail
                            ? true
                            : false
                        }
                      />
                      {validation.touched.companyEmail &&
                        validation.errors.companyEmail ? (
                        <FormFeedback type="invalid">
                          {validation.errors.companyEmail}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <Row className="mb-4">
                      <Col lg={6}>
                        <Label
                          htmlFor="companyEmail"
                          className="form-label fw-normal p-0 m-0 mb-1"
                        >
                          First name
                        </Label>
                        <Input
                          name="firstName"
                          className="form-control"
                          placeholder="First name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstName || ""}
                          invalid={
                            validation.touched.firstName &&
                              validation.errors.firstName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstName &&
                          validation.errors.firstName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstName}
                          </FormFeedback>
                        ) : null}

                      </Col>

                      <Col lg={6}>
                        <Label
                          htmlFor="lastName"
                          className="form-label fw-normal p-0 m-0 mb-1"
                        >
                          Last name
                        </Label>
                        <Input
                          name="lastName"
                          className="form-control"
                          placeholder="Last name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastName || ""}
                          invalid={
                            validation.touched.lastName &&
                              validation.errors.lastName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastName &&
                          validation.errors.lastName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastName}
                          </FormFeedback>
                        ) : null}

                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col lg={6}>
                        <Label
                          htmlFor="country"
                          className="form-label fw-normal p-0 m-0 mb-1"
                        >
                          Country
                        </Label>
                        <Input
                          name="country"
                          className="form-control"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.country || ""}
                        />

                      </Col>

                      <Col lg={6} className="mb-3">
                        <Label
                          htmlFor="companyWebsite"
                          className="form-label fw-normal p-0 m-0 mb-1"
                        >
                          Company website (optional)
                        </Label>
                        <Input
                          name="companyWebsite"
                          className="form-control"
                          placeholder="Website"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.companyWebsite || ""}
                        />
                      </Col>
                    </Row>

                    <div className="form-check">
                      <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                      <Label className="form-check-label fw-light fs-12" htmlFor="auth-remember-check">
                        I want to receive Roware news, trends, offers, or blogs by email.</Label>
                    </div>

                    <p className="mt-3 fs-12">By signing up, you confirm that you’ve read and accepted our <Link to="" className="text-decoration-underline">Terms and Conditions</Link> and our <Link to="" className="text-decoration-underline">Privacy Notice.</Link></p>

                    <div className="mt-5">
                      <Button
                        // disabled={invalid}
                        // disabled={validation.errors || (invalidEmail && invalidFirstname && invalidLastname)}
                        disabled={(validation.values.companyEmail && validation.values.companyEmail && validation.values.companyEmail) === " " ? true : loading ? true : false}
                        className={`${validation.errors} ? btn btn-dark w-100 text-muted : btn btn-warning w-100 text-white`}
                        type="submit"
                      >
                        {error ? null : loading ? (
                          <Spinner size="sm" className="me-2">
                            {" "}
                            Loading...{" "}
                          </Spinner>
                        ) : null}
                        START FREE TRIAL
                      </Button>
                    </div>
                    <p className="mt-3 fs-12 text-center">No credit card required</p>
                  </Form>
                </div>


                <div className="d-flex justify-content-center">
                  <div className="" style={{ padding: "0 5px", flex: "1" }}><div className="border-bottom" style={{ height: "50%" }}></div></div>
                  <p className="mt-3">OR CONTINUE WITH</p>
                  <div className="" style={{ padding: "0 5px", flex: "1" }}><div className="border-bottom" style={{ height: "50%" }}></div></div>
                </div>
                <Row>
                  <Col lg={6} className="mt-2">
                    <div>
                      <Button
                        color="secondary"
                        className="w-100"
                        outline
                        type="submit"
                      >
                        {error ? null : loading ? (
                          <Spinner size="sm" className="me-2">
                            {" "}
                            Loading...{" "}
                          </Spinner>
                        ) : null}
                        <span><FcGoogle size="16px" style={{ marginBottom: '1px' }} /> <p className="ms-1 d-inline text-uppercase fs-13">google</p></span>
                      </Button>
                    </div>
                  </Col>
                  <Col lg={6} className="mt-2 mb-2">
                    <div>
                      <Button
                        color="secondary"
                        className="w-100"
                        outline
                        type="submit"
                      >
                        {error ? null : loading ? (
                          <Spinner size="sm" className="me-2">
                            {" "}
                            Loading...{" "}
                          </Spinner>
                        ) : null}
                        <span><FaGithub size="16px" color="black" style={{ marginBottom: '1px' }} /> <p className="ms-1 d-inline text-uppercase fs-13">github</p></span>
                      </Button>
                    </div>
                  </Col>
                </Row>

              </div>
            </Card>

          </Col>
        </Row>
        {/* </Container> */}
      </div>
    </React.Fragment>
  );
};

export default Register2;