import React, { useState } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

import Profile from './Profile';
import Team from './Team';
import ApiKeys from './ApiKeys';

const Section = () => {
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card className="mt-n4 mx-n4 shadow-none border-top">
                        <CardBody className="pb-0 pt-0 px-4">
                            <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' }, "fw-normal")}
                                        onClick={() => { toggleTab('1'); }}
                                        href="#">
                                        Profile
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' }, "fw-normal")}
                                        onClick={() => { toggleTab('2'); }}
                                        href="#">
                                        Team
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '3' }, "fw-normal")}
                                        onClick={() => { toggleTab('3'); }}
                                        href="#">
                                        API Keys & Webhooks
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                        <TabPane tabId="1">
                            <Profile />
                        </TabPane>
                        <TabPane tabId="2">
                            <Team />
                        </TabPane>
                        <TabPane tabId="3">
                            <ApiKeys />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Section;