import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { ACCOUNT_GET_PROFILE, ACCOUNT_JOIN_TEAM, ACCOUNT_LOGIN, ACCOUNT_LOGOUT, ACCOUNT_SIGN_UP } from "./actionType";
import { accountResponseSuccess, accountResponseError } from "./action";

import { getProfile, logout, postJoinTeam, postLogin, postSignUp } from "../../helpers/backend_helper";

function* login({ payload: { data, history } }) {
  try {
    const response = yield call(postLogin, data);
    console.log('-----------', response, '------------');
    if (response.status === 'success') {
      yield put(accountResponseSuccess(ACCOUNT_LOGIN, response.data));
      history('/dashboard');
    } else {
      yield put(accountResponseError(ACCOUNT_LOGIN, response.msg));
    }
  } catch (error) {
    yield put(
      accountResponseError(ACCOUNT_LOGIN, error?.response?.data?.msg ?? error.message)
    );
  }
}

function* signup({ payload: { data, history } }) {
  try {
    const response = yield call(postSignUp, data);
    if (response.status === 'success') {
      yield put(accountResponseSuccess(ACCOUNT_SIGN_UP, response.data));
      history("/login");
    } else {
      yield put(accountResponseError(ACCOUNT_SIGN_UP, response.msg));
    }
  } catch (error) {
    yield put(
      accountResponseError(ACCOUNT_SIGN_UP, error?.response?.data?.msg ?? error.message)
    );
  }
}

function* joinTeam({ payload: { data, history } }) {
  try {
    const response = yield call(postJoinTeam, data);
    if (response.status === 'success') {
      yield put(accountResponseSuccess(ACCOUNT_JOIN_TEAM, response.data));
      history("/login");
    } else {
      yield put(accountResponseError(ACCOUNT_JOIN_TEAM, response.msg));
    }
  } catch (error) {
    yield put(
      accountResponseError(ACCOUNT_JOIN_TEAM, error?.response?.data?.msg ?? error.message)
    );
  }
}

function* profile() {
  try {
    const response = yield call(getProfile);
    if (response.status === 'success') {
      yield put(accountResponseSuccess(ACCOUNT_GET_PROFILE, response.data));
    } else {
      yield put(accountResponseError(ACCOUNT_GET_PROFILE, response.msg));
    }
  } catch (error) {
    yield put(
      accountResponseError(ACCOUNT_GET_PROFILE, error?.response?.data?.msg ?? error.message)
    );
  }
}

function* accountLogout({ payload: { history } }) {
  try {
    const response = yield call(logout);
    if (response.status === 'success') {
      window.location.reload();
    }
  } catch (error) {
    //
  }
}

export function* watchSignUp() {
  yield takeEvery(ACCOUNT_SIGN_UP, signup);
}
export function* watchJoinTeam() {
  yield takeEvery(ACCOUNT_JOIN_TEAM, joinTeam);
}
export function* watchLogin() {
  yield takeEvery(ACCOUNT_LOGIN, login);
}
export function* watchGetProfile() {
  yield takeEvery(ACCOUNT_GET_PROFILE, profile);
}
export function* watchLogout() {
  yield takeEvery(ACCOUNT_LOGOUT, accountLogout);
}

function* accountSaga() {
  yield all([fork(watchSignUp)]);
  yield all([fork(watchJoinTeam)]);
  yield all([fork(watchLogin)]);
  yield all([fork(watchGetProfile)]);
  yield all([fork(watchLogout)]);
}

export default accountSaga;
