import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import NonAuthLayout from "../Layouts/NonAuthLayout";

import { publicRoutes } from "./allRoutes";
import { useDispatch, useSelector } from "react-redux";
import { accountGetProfile } from "../store/actions";
import DynamicRoutes from "./DynamicRoutes";

const Index = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { account, isResolving } = useSelector((state) => ({
    account: state.Account.account,
    isResolving: state.Account.isResolving,
  }));

  useEffect(() => {
    dispatch(accountGetProfile());
  }, [location?.pathname]);

  if (!account && isResolving) {
    // react-loadingg
    return (
      <div
        style={{
          display: "absolute",
          bottom: 0,
          width: "100%",
          textAlign: "center",
        }}
      >
        <div style={{ marginTop: "20%" }}>Loading...</div>
      </div>
    );
  }

  return (
    <React.Fragment>
      {!account && (
        <Routes>
          <Route>
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                key={idx}
                exact={true}
              />
            ))}
          </Route>
        </Routes>
      )}
      {account && <DynamicRoutes />}
    </React.Fragment>
  );
};

export default Index;
