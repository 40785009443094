import {
  PQ_API_SUCCESS,
  PQ_API_ERROR,
  PQ_GET_EXAMS,
  PQ_GET_SUBJECTS,
  PQ_GET_SCHOOLS,
  PQ_GET_YEARS,
  PQ_GET_TESTS,
  PQ_SAVE_SELECTIONS,
  PQ_SET_EXAM,
  PQ_SET_QUESTION,
  PQ_GET_QUESTIONS,
  PQ_DELETE_ITEM,
  PQ_SET_TEST,
  PQ_SET_SUBJECT,
  PQ_SET_SCHOOL,
  PQ_SET_YEAR,
} from "./actionType";

export const pqApiSuccess = (actionType, data) => ({
  type: PQ_API_SUCCESS,
  payload: { actionType, data },
});

export const pqApiError = (actionType, error) => ({
  type: PQ_API_ERROR,
  payload: { actionType, error },
});

export const getExams = (data) => ({
  type: PQ_GET_EXAMS,
  payload: { data },
});

export const getSubjects = (data) => ({
  type: PQ_GET_SUBJECTS,
  payload: { data },
});
export const getSchools = (data) => ({
  type: PQ_GET_SCHOOLS,
  payload: { data },
});
export const getYears = (data) => ({
  type: PQ_GET_YEARS,
  payload: { data },
});
export const getTests = (data) => ({
  type: PQ_GET_TESTS,
  payload: { data },
});
export const getQuestions = (data) => ({
  type: PQ_GET_QUESTIONS,
  payload: { data },
});
export const saveSelections = (data) => ({
  type: PQ_SAVE_SELECTIONS,
  payload: { data },
});

export const setExam = (data) => ({
  type: PQ_SET_EXAM,
  payload: data,
});
export const setSubject = (data) => ({
  type: PQ_SET_SUBJECT,
  payload: data,
});
export const setSchool = (data) => ({
  type: PQ_SET_SCHOOL,
  payload: data,
});
export const setYear = (data) => ({
  type: PQ_SET_YEAR,
  payload: data,
});
export const setTest = (data) => ({
  type: PQ_SET_TEST,
  payload: data,
});
export const setQuestion = (data) => ({
  type: PQ_SET_QUESTION,
  payload: data,
});
export const deleteItem = (data) => ({
  type: PQ_DELETE_ITEM,
  payload: { data },
});
