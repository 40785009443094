export const PQ_API_SUCCESS = "PQ_API_SUCCESS";
export const PQ_API_ERROR = "PQ_API_ERROR";

export const PQ_GET_EXAMS = "PQ_GET_EXAMS";
export const PQ_GET_SUBJECTS = "PQ_GET_SUBJECTS";
export const PQ_GET_SCHOOLS = "PQ_GET_SCHOOLS";
export const PQ_GET_YEARS = "PQ_GET_YEARS";
export const PQ_GET_TESTS = "PQ_GET_TESTS";
export const PQ_GET_QUESTIONS = "PQ_GET_QUESTIONS";
export const PQ_SAVE_SELECTIONS = "PQ_SAVE_SELECTIONS";

export const PQ_SET_EXAM = "PQ_SET_EXAM";
export const PQ_SET_SUBJECT = "PQ_SET_SUBJECT";
export const PQ_SET_SCHOOL = "PQ_SET_SCHOOL";
export const PQ_SET_YEAR = "PQ_SET_YEAR";
export const PQ_SET_TEST = "PQ_SET_TEST";
export const PQ_SET_QUESTION = "PQ_SET_QUESTION";

export const PQ_DELETE_ITEM = "PQ_DELETE_ITEM";
