import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import Select from "react-select";

import PQItemActions from "../../../../Components/PQItemActions";
import { Col, Form, Input, Label, Modal, ModalBody, Row } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import DeleteModal from "../../../../Components/DeleteModal";
import { deleteItem, getExams, getSchools, getSubjects, getTests, getYears, setTest } from "../../../../store/actions";
import { MENU_TEST } from "./section";

const Test = (props) => {
    const { createModal } = props;
    const dispatch = useDispatch();

    const [vals, setVals] = useState({});
    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToEdit, setItemToEdit] = useState(null);

    const { tests, exams, subjects, schools, years, isDeleting, isDeleted, isTestSet } = useSelector((state) => ({
        tests: state.PQ.tests,
        exams: state.PQ.exams,
        subjects: state.PQ.subjects,
        schools: state.PQ.schools,
        years: state.PQ.years,
        isDeleting: state.PQ.isDeleting,
        isDeleted: state.PQ.isDeleted,
        isTestSet: state.PQ.isTestSet,
    }));

    useEffect(() => {
        dispatch(getTests());
        if (isTestSet) closeModal();
    }, [dispatch, isTestSet, isDeleted]);

    useEffect(() => {
        dispatch(getExams());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getSubjects());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getSchools());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getYears());
    }, [dispatch]);

    useEffect(() => {
        if (isDeleted) setItemToDelete(null);
    }, [isDeleted]);

    const handleDeletePQData = () => {
        dispatch(deleteItem({ type: itemToDelete.type, id: itemToDelete.id }));
    };

    const closeModal = () => {
        setItemToEdit(null);
        props?.closeModal();
        validation.resetForm();
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { name: itemToEdit?.name || "", slug: itemToEdit?.slug || "" },
        validationSchema: Yup.object({
            name: Yup.string().required("Please enter test name"),
        }),
        onSubmit: (values) => {
            if (itemToEdit) {
                values.testID = itemToEdit._id;
            }
            if (vals.exam) {
                values.exam = vals.exam?.value;
            }
            if (vals.subject) {
                values.subject = vals.subject?.value;
            }
            if (vals.school) {
                values.school = vals.school?.value;
            }
            if (vals.year) {
                values.year = vals.year?.value;
            }
            dispatch(setTest(values));
        },
    });

    return (
        <React.Fragment>
            <DeleteModal
                itemName={itemToDelete?.name}
                show={itemToDelete}
                isDeleting={isDeleting}
                onDeleteClick={() => handleDeletePQData()}
                onCloseClick={() => setItemToDelete(null)}
            />
            <div className="accordion accordion-flush">
                {tests.map(
                    (pq, index) =>
                        <div
                            key={`test-${pq._id}`}
                            className="accordion-item d-flex align-items-center d-hover"
                        >
                            <div
                                className={classnames(
                                    "fc-event",
                                    "text-black",
                                )}
                            >
                                {pq?.name}
                            </div>
                            <PQItemActions
                                item={pq}
                                type={MENU_TEST.toLowerCase()}
                                title={pq.name}
                                id={pq._id}
                                onDelete={setItemToDelete}
                                onEdit={setItemToEdit}
                            />
                        </div>

                )}
            </div>
            <Modal
                isOpen={createModal !== '' || itemToEdit}
                toggle={closeModal}
                centered
                backdrop='static'
            >
                <ModalBody className="pt-0 px-0">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col lg={12}>
                                <div className="d-flex pt-2 pb-3 mb-4 bg-soft-primary rounded px-3">
                                    <div className="flex-grow-1">
                                        <h5
                                            className="modal-title text-muted"
                                            id="createMemberLabel"
                                        >
                                            Add Test
                                        </h5>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex gap-3 align-items-center">
                                            <button
                                                type="button"
                                                className="btn-close btn-close-black"
                                                onClick={() => closeModal()}
                                                id="createMemberBtn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            ></button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <Label
                                    // htmlFor="tests"
                                    className="form-label ms-4 fw-normal p-0 m-0 text-muted"
                                >
                                    Name *
                                </Label>
                                <div className="mb-3 mx-3 form-control-sm">
                                    <Input
                                        name="name"
                                        className="form-control border border-1"
                                        value={validation.values.name || ""}
                                        onChange={validation.handleChange}
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <Label
                                    // htmlFor="tests"
                                    className="form-label ms-4 fw-normal p-0 m-0 text-muted"
                                >
                                    URL Slug
                                </Label>
                                <div className="mb-3 mx-3 form-control-sm">
                                    <Input
                                        name="slug"
                                        className="form-control border border-1"
                                        value={validation.values.slug || ""}
                                        onChange={validation.handleChange}
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <Label
                                    // htmlFor="choices-single-default"
                                    className="form-label ms-4 fw-normal p-0 m-0 text-muted"
                                >
                                    Exam
                                </Label>
                                <div className="mb-3 mx-3 form-control-sm">
                                    <Select
                                        value={vals?.exam}
                                        onChange={(val) => setVals({ ...vals, exam: val })}
                                        options={exams?.map((i) => ({
                                            label: i.name,
                                            value: i._id,
                                        }))}
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <Label
                                    // htmlFor="choices-single-default"
                                    className="form-label ms-4 fw-normal p-0 m-0 text-muted"
                                >
                                    Subject
                                </Label>
                                <div className="mb-3 mx-3 form-control-sm">
                                    <Select
                                        value={vals?.subject}
                                        onChange={(val) => setVals({ ...vals, subject: val })}
                                        options={subjects?.map((i) => ({
                                            label: i.name,
                                            value: i._id,
                                        }))}
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <Label
                                    // htmlFor="choices-single-default"
                                    className="form-label ms-4 fw-normal p-0 m-0 text-muted"
                                >
                                    School
                                </Label>
                                <div className="mb-3 mx-3 form-control-sm">
                                    <Select
                                        value={vals?.school}
                                        onChange={(val) => setVals({ ...vals, school: val })}
                                        options={schools?.map((i) => ({
                                            label: i.name,
                                            value: i._id,
                                        }))}
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <Label
                                    // htmlFor="choices-single-default"
                                    className="form-label ms-4 fw-normal p-0 m-0 text-muted"
                                >
                                    Year
                                </Label>
                                <div className="mb-3 mx-3 form-control-sm">
                                    <Select
                                        value={vals?.year}
                                        onChange={(val) => setVals({ ...vals, year: val })}
                                        options={years?.map((i) => ({
                                            label: i.name,
                                            value: i._id,
                                        }))}
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="hstack gap-2 justify-content-end mt-4 mx-3">
                                    <button
                                        type="button"
                                        className="btn btn-light btn-sm"
                                        onClick={() => closeModal()}
                                    >
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary btn-sm">
                                        Add Test
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment >
    );
};

Test.propTypes = {
    createModal: PropTypes.string,
    closeModal: PropTypes.func,
};

export default Test;