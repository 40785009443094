import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import Select from "react-select";

import PQItemActions from "../../../../Components/PQItemActions";
import { Button, ButtonGroup, Col, Collapse, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { useFormik } from "formik";
import DeleteModal from "../../../../Components/DeleteModal";
import { deleteItem, getQuestions, getSubjects, getTests, setQuestion } from "../../../../store/actions";
import { Interweave } from "interweave";
import { MENU_QUESTION } from "./section";

const LETTERS = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"];

const Question = (props) => {
    const { filter, createModal } = props;

    const dispatch = useDispatch();

    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToEdit, setItemToEdit] = useState(null);
    console.log('itemToEdit', itemToEdit);

    const [vals, setVals] = useState({});
    const [options, setOptions] = useState([]);
    const [timestamp, setTimestamp] = useState(new Date());
    const [expanded, setExpanded] = useState(null);

    const { questions, subjects, tests, isDeleting, isDeleted, isQuestionSet } = useSelector((state) => ({
        questions: state.PQ.questions,
        subjects: state.PQ.subjects,
        tests: state.PQ.tests,
        isDeleting: state.PQ.isDeleting,
        isDeleted: state.PQ.isDeleted,
        isQuestionSet: state.PQ.isQuestionSet,
    }));

    useEffect(() => {
        dispatch(getQuestions(filter));
        if (isQuestionSet) closeModal();
    }, [dispatch, filter, isQuestionSet, isDeleted]);

    useEffect(() => {
        dispatch(getSubjects());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getTests());
    }, [dispatch]);

    useEffect(() => {
        if (isDeleted) setItemToDelete(null);
    }, [isDeleted]);

    const handleDeletePQData = () => {
        dispatch(deleteItem({ type: itemToDelete.type, id: itemToDelete.id }));
    };

    const closeModal = () => {
        setItemToEdit(null);
        props?.closeModal();
        setVals({});
        setOptions([]);
        validation.resetForm();
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            section: itemToEdit?.section || "",
            instruction: itemToEdit?.instruction || "",
            passage: itemToEdit?.passage || "",
            question: itemToEdit?.question || "",
        },
        // validationSchema: Yup.object({
        //   question: Yup.string().required("Please enter question"),
        // }),
        onSubmit: (values) => {
            if (itemToEdit) {
                values.questionID = itemToEdit._id;
            }

            if (
                options.length > 0 &&
                vals?.test?.value &&
                vals?.subject?.value &&
                vals?.ans
            ) {
                values.test = vals.test.value;
                values.subject = [vals.subject.value];
                values.options = options;
                values.ans = vals.ans;
                dispatch(setQuestion(values));
            }
        },
    });

    const addOption = () => {
        const len = options.length;
        if (len === LETTERS.length) return;

        let arr = options;
        arr.push({ label: LETTERS[len], value: "" });
        setOptions(arr);
        setTimestamp(new Date());
    };

    const removeOption = (index) => {
        let x = 0;
        let arr = [];
        for (let i = 0; i < options.length; i++) {
            if (i != index) {
                arr.push({ label: LETTERS[x], value: options[i].value });
                x++;
            }
        }
        setOptions(arr);
    };

    const optionValChange = (i, e) => {
        let arr = options;
        arr[i].value = e.target.value;
        setOptions(arr);
        setTimestamp(new Date());
    };

    const onEdit = (item) => {
        setOptions(item?.options);
        setItemToEdit(item);
    }

    return (
        <React.Fragment>
            <DeleteModal
                itemName={itemToDelete?.name}
                show={itemToDelete}
                isDeleting={isDeleting}
                onDeleteClick={() => handleDeletePQData()}
                onCloseClick={() => setItemToDelete(null)}
            />
            <div className="accordion accordion-flush">
                {questions.map(
                    (pq, index) =>
                        <div
                            key={`question-${index}`}
                            className="accordion-item m-0 b-0"
                        >
                            <div
                                className={classnames("fc-event", "text-black", 'd-flex', 'd-hover')}
                                onClick={() =>
                                    setExpanded(
                                        expanded === pq._id ? null : pq._id
                                    )
                                }
                            // dangerouslySetInnerHTML={{ __html: pq?.question ?? pq?.passage }}
                            >

                                <Interweave content={pq?.question ?? pq?.passage} />
                                <PQItemActions
                                    item={pq}
                                    type={MENU_QUESTION.toLowerCase()}
                                    title={pq.name} id={pq._id}
                                    onDelete={setItemToDelete}
                                    onEdit={onEdit} />
                            </div>
                            <Collapse
                                isOpen={expanded === pq._id}
                                className="accordion-collapse"
                            >
                                <div className="accordion-body row">
                                    {pq?.options?.map((opt) => (
                                        <div
                                            key={`question-${index}-option-${opt.label}`}
                                            className="fc-event text-black mb-2"
                                        >
                                            <Interweave content={`(${opt.label}) ${opt.value}`} />
                                        </div>
                                    ))}
                                </div>
                            </Collapse>
                        </div>

                )}
            </div>
            <Modal
                className="modal-lg"
                isOpen={createModal !== '' || itemToEdit}
                toggle={closeModal}
                centered
                backdrop='static'
            >
                <ModalHeader className="p-3 bg-light" toggle={closeModal}>
                    Add Question
                </ModalHeader>
                <ModalBody className="px-0">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col lg={6}>
                                <Label className="form-label ms-4 fw-normal p-0 m-0 text-muted">
                                    Test *
                                </Label>
                                <div className="mb-3 mx-3 form-control-sm">
                                    <Select
                                        required
                                        value={vals?.test}
                                        onChange={(val) => setVals({ ...vals, test: val })}
                                        options={tests?.map((i) => ({
                                            label: i.name,
                                            value: i._id,
                                        }))}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <Label className="form-label ms-4 fw-normal p-0 m-0 text-muted">
                                    Subject *
                                </Label>
                                <div className="mb-3 mx-3 form-control-sm">
                                    <Select
                                        required
                                        value={vals?.subject}
                                        onChange={(val) => setVals({ ...vals, subject: val })}
                                        options={subjects?.map((i) => ({
                                            label: i.name,
                                            value: i._id,
                                        }))}
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <Label className="form-label ms-4 fw-normal p-0 m-0 text-muted">
                                    Section
                                </Label>
                                <div className="mb-3 mx-3 form-control-sm">
                                    <Input
                                        name="section"
                                        className="form-control border border-1"
                                        value={validation.values.section || ""}
                                        onChange={validation.handleChange}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <Label className="form-label ms-4 fw-normal p-0 m-0 text-muted">
                                    Instruction
                                </Label>
                                <div className="mb-3 mx-3 form-control-sm">
                                    <textarea
                                        name="instruction"
                                        className="form-control"
                                        rows="2"
                                        value={validation.values.instruction || ""}
                                        onChange={validation.handleChange}
                                    ></textarea>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <Label className="form-label ms-4 fw-normal p-0 m-0 text-muted">
                                    Passage
                                </Label>
                                <div className="mb-3 mx-3 form-control-sm">
                                    <textarea
                                        name="passage"
                                        className="form-control"
                                        rows="2"
                                        value={validation.values.passage || ""}
                                        onChange={validation.handleChange}
                                    ></textarea>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <Label className="form-label ms-4 fw-normal p-0 m-0 text-muted">
                                    Question *
                                </Label>
                                <div className="mb-3 mx-3 form-control-sm">
                                    <textarea
                                        name="question"
                                        className="form-control"
                                        rows="2"
                                        value={validation.values.question || ""}
                                        onChange={validation.handleChange}
                                    ></textarea>
                                </div>
                            </Col>
                            {options.map((i, x) => (
                                <Col lg={6} key={`new-opt-${x}`}>
                                    <Label className="form-label ms-4 fw-normal p-0 m-0 text-muted">
                                        Option {i.label.toUpperCase()}
                                    </Label>
                                    <div className="mb-3 mx-3 form-control-sm">
                                        <textarea
                                            name="option"
                                            className="form-control"
                                            rows="2"
                                            value={i?.value}
                                            onChange={(e) => optionValChange(x, e)}
                                        ></textarea>
                                        <i
                                            className="ri-close-circle-fill position-absolute top-1 end-1 translate-middle"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => removeOption(x)}
                                        ></i>
                                    </div>
                                </Col>
                            ))}
                            <Col lg={12}>
                                <div className="mb-3 mx-3 form-control-sm">
                                    <Button
                                        className="btn-link p-0"
                                        color="link"
                                        onClick={addOption}
                                    >
                                        Add Option
                                    </Button>
                                </div>
                            </Col>
                            {options.length > 0 && (
                                <Col lg={12}>
                                    <Label className="form-label ms-4 fw-normal p-0 m-0 text-muted">
                                        Select answer
                                    </Label>
                                    <div className="mb-3 mx-3 form-control-sm">
                                        <ButtonGroup className="me-2">
                                            {[...options, { label: "none" }].map((i, x) => (
                                                <Button
                                                    key={`new-ans-${x}`}
                                                    color="light"
                                                    className={`btn-soft-primary fs-12 fw-normal ${vals?.ans === i.label ? "active" : ""
                                                        }`}
                                                    onClick={() => setVals({ ...vals, ans: i.label })}
                                                >
                                                    {i.label.toUpperCase()}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                    </div>
                                </Col>
                            )}
                            <Col lg={12}>
                                <div className="hstack gap-2 justify-content-end mt-4 mx-3">
                                    <button
                                        type="button"
                                        className="btn btn-light btn-sm btn-ghost-danger"
                                        onClick={() => closeModal()}
                                    >
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary btn-sm">
                                        Add Question
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

Question.propTypes = {
    createModal: PropTypes.string,
    closeModal: PropTypes.func,
};

export default Question;