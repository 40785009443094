import {
  TEAM_RESPONSE_SUCCESS,
  TEAM_RESPONSE_ERROR,
  TEAM_LIST_ROLES,
  TEAM_INVITE_MEMBER
} from "./actionType";

export const teamResponseSuccess = (actionType, data) => ({
  type: TEAM_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const teamResponseError = (actionType, error) => ({
  type: TEAM_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const listRoles = () => ({
  type: TEAM_LIST_ROLES,
});

export const inviteMember = (data) => ({
  type: TEAM_INVITE_MEMBER,
  payload: { data },
});
