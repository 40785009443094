import {
  PQ_API_SUCCESS,
  PQ_API_ERROR,
  PQ_GET_EXAMS,
  PQ_GET_SUBJECTS,
  PQ_GET_SCHOOLS,
  PQ_GET_YEARS,
  PQ_GET_TESTS,
  PQ_SAVE_SELECTIONS,
  PQ_SET_EXAM,
  PQ_SET_SUBJECT,
  PQ_SET_SCHOOL,
  PQ_SET_TEST,
  PQ_SET_YEAR,
  PQ_SET_QUESTION,
  PQ_GET_QUESTIONS,
  PQ_DELETE_ITEM,
} from "./actionType";

const INIT_STATE = {
  exams: [],
  subjects: [],
  schools: [],
  years: [],
  tests: [],
  questions: [],
  error: null,
  isSaving: false,
  isExamSet: false,
  isSubjectSet: false,
  isSchoolSet: false,
  isYearSet: false,
  isTestSet: false,
  isQuestionSet: false,
  isDeleting: false,
  isDeleted: false,
};

const PQ = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PQ_API_SUCCESS:
      switch (action.payload.actionType) {
        case PQ_GET_EXAMS:
          return { ...state, exams: action.payload.data, isExamSet: false, isDeleting: false, isDeleted: false };
        case PQ_GET_SUBJECTS:
          return {
            ...state,
            subjects: action.payload.data,
            isSubjectSet: false, isDeleting: false, isDeleted: false
          };
        case PQ_GET_SCHOOLS:
          return {
            ...state,
            schools: action.payload.data,
            isSchoolSet: false, isDeleting: false, isDeleted: false
          };
        case PQ_GET_YEARS:
          return { ...state, years: action.payload.data, isYearSet: false, isDeleting: false, isDeleted: false };
        case PQ_GET_TESTS:
          return { ...state, tests: action.payload.data, isTestSet: false, isDeleting: false, isDeleted: false };
        case PQ_GET_QUESTIONS:
          return {
            ...state,
            questions: action.payload.data,
            isQuestionSet: false, isDeleting: false, isDeleted: false
          };
        case PQ_SAVE_SELECTIONS:
          return { ...state, isSaving: false };
        case PQ_SET_EXAM:
          return { ...state, isExamSet: true };
        case PQ_SET_SUBJECT:
          return { ...state, isSubjectSet: true };
        case PQ_SET_SCHOOL:
          return { ...state, isSchoolSet: true };
        case PQ_SET_YEAR:
          return { ...state, isYearSet: true };
        case PQ_SET_TEST:
          return { ...state, isTestSet: true };
        case PQ_SET_QUESTION:
          return { ...state, isQuestionSet: true };
        case PQ_DELETE_ITEM:
          return { ...state, isDeleting: false, isDeleted: true };
        default:
          return state;
      }
    case PQ_API_ERROR:
      switch (action.payload.actionType) {
        case PQ_GET_EXAMS:
          return { ...state, error: action.payload.error };
        case PQ_SAVE_SELECTIONS:
          return { ...state, isSaving: false };
        case PQ_DELETE_ITEM:
          return { ...state, isDeleting: false, isDeleted: false };
        default:
          return state;
      }
    case PQ_SAVE_SELECTIONS:
      return { ...state, isSaving: true };
    case PQ_DELETE_ITEM:
      return { ...state, isDeleting: true };
    default:
      return state;
  }
};
export default PQ;
