import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";
import "react-perfect-scrollbar/dist/css/styles.css";
import Select from "react-select";
import Exams from "./Exams";
import Subject from "./Subject";
import School from "./School";
import Year from "./Year";
import Test from "./Test";
import Question from "./Question";

export const MENU_EXAMS = "Exam";
export const MENU_SUBJECT = "Subject";
export const MENU_SCHOOL = "School";
export const MENU_YEAR = "Year";
export const MENU_TEST = "Test";
export const MENU_QUESTION = "Question";

const PQApi = () => {

  document.title = "PQ API Overview";

  const dispatch = useDispatch();

  const [categories, setCategories] = useState([
    MENU_EXAMS,
    MENU_SUBJECT,
    MENU_SCHOOL,
    MENU_YEAR,
    MENU_TEST,
    MENU_QUESTION,
  ]);
  const [selectedCategory, setSelectedCategory] = useState(MENU_EXAMS);
  const [createModal, setCreateModal] = useState("");
  const [filter, setFilter] = useState({});

  const { exams, subjects, schools, years } = useSelector((state) => ({
    exams: state.PQ.exams,
    subjects: state.PQ.subjects,
    schools: state.PQ.schools,
    years: state.PQ.years,
  }));

  // useEffect(() => {
  //   dispatch(getExams());
  //   if (isExamSet) closeModal();
  // }, [dispatch, isExamSet, isDeleted]);

  const closeModal = () => {
    setCreateModal("");
  };

  //search api
  const searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();
    var userList = document.getElementsByClassName("users-list");
    Array.prototype.forEach.call(userList, function (el) {
      li = el.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("a")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="g-2">
            <Col lg={6}>
              <ul className="list-inline categories-filter animation-nav">
                {categories.map((i, x) => (
                  <li key={`category-tab-${x}`} className="list-inline-item">
                    <Link
                      to="#"
                      onClick={() => setSelectedCategory(i)}
                      className={
                        selectedCategory === i
                          ? "categories active"
                          : "categories"
                      }
                      data-filter="*"
                    >
                      {i}
                    </Link>
                  </li>
                ))}
              </ul>
            </Col>
            <Col className="col-sm-auto ms-auto">
              <div className="list-grid-nav hstack gap-1">
                <Button
                  color="info"
                  id={`create-${selectedCategory.toLowerCase()}-btn`}
                  className="btn btn-soft-info nav-link btn-icon fs-14 filter-button"
                  onClick={() => setCreateModal(selectedCategory)}
                >
                  <i className="ri-add-line"></i>
                  {/* <UncontrolledTooltip
                    placement="top"
                    target={`create-${selectedCategory.toLowerCase()}-btn`}
                  >
                    {`Add ${selectedCategory}`}
                  </UncontrolledTooltip> */}
                </Button>

                <Button
                  color="info"
                  id="list-view-button"
                  className="btn btn-soft-info nav-link  btn-icon fs-14 filter-button"
                >
                  <i className="ri-list-unordered"></i>
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div
                className="w-100 bg-white"
              // onDrop={onRemove}
              // onDragOver={onDragOver}
              >
                <div className="px-3 pt-4 mb-3">
                  <Row className="g-2">
                    <Col lg={4}>
                      <div className="search-box d-flex">
                        <input
                          // onKeyUp={searchUsers}
                          id="search-user"
                          type="text"
                          className="form-control bg-light border-light"
                          placeholder="Search here..."
                        />
                        <i className="ri-search-2-line search-icon"></i>
                      </div>
                    </Col>
                    <Col className="col-sm-auto ms-auto">
                      <div className="list-grid-nav hstack gap-1">
                        <Select
                          placeholder={"Select Exam"}
                          onChange={(val) =>
                            setFilter({ ...filter, exam: val.value })
                          }
                          options={exams?.map((i) => ({
                            label: i.name,
                            value: i._id,
                          }))}
                        />
                        <Select
                          placeholder="Select Subject"
                          onChange={(val) =>
                            setFilter({ ...filter, subject: val.value })
                          }
                          options={subjects?.map((i) => ({
                            label: i.name,
                            value: i._id,
                          }))}
                        />
                        <Select
                          placeholder="Select School"
                          onChange={(val) =>
                            setFilter({ ...filter, school: val.value })
                          }
                          options={schools?.map((i) => ({
                            label: i.name,
                            value: i._id,
                          }))}
                        />
                        <Select
                          placeholder="Select Year"
                          onChange={(val) =>
                            setFilter({ ...filter, year: val.value })
                          }
                          options={years?.map((i) => ({
                            label: i.name,
                            value: i._id,
                          }))}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>

                <div>
                  <SimpleBar
                    className="chat-room-list pt-3"
                    style={{ margin: "-16px 0px 0px" }}
                  >
                    <div className="d-flex align-items-center px-4 mb-2">
                      <div className="flex-grow-1">
                        <h4 className="mb-0 fs-11 text-muted text-uppercase">
                          Available {selectedCategory}
                        </h4>
                      </div>
                    </div>
                    {selectedCategory === MENU_EXAMS && <Exams createModal={createModal} closeModal={closeModal} />}
                    {selectedCategory === MENU_SUBJECT && <Subject createModal={createModal} closeModal={closeModal} />}
                    {selectedCategory === MENU_SCHOOL && <School createModal={createModal} closeModal={closeModal} />}
                    {selectedCategory === MENU_YEAR && <Year createModal={createModal} closeModal={closeModal} />}
                    {selectedCategory === MENU_TEST && <Test createModal={createModal} closeModal={closeModal} />}
                    {selectedCategory === MENU_QUESTION && <Question filter={filter} createModal={createModal} closeModal={closeModal} />}
                  </SimpleBar>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  );
};

export default PQApi;
