import {
  ACCOUNT_RESPONSE_SUCCESS,
  ACCOUNT_LOGIN,
  ACCOUNT_RESPONSE_ERROR,
  ACCOUNT_GET_PROFILE,
  ACCOUNT_SIGN_UP,
} from "./actionType";

const INIT_STATE = {
  account: null,
  isResolving: true,
  accountError: null,
  loading: false,
  error: null,
};

const Account = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACCOUNT_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ACCOUNT_SIGN_UP:
          return { ...state, account: action.payload.data, loading: false };
        case ACCOUNT_LOGIN:
          return { ...state, account: action.payload.data, loading: false };
        case ACCOUNT_GET_PROFILE:
          return { ...state, account: action.payload.data, isResolving: false };
        default:
          return state;
      }
    case ACCOUNT_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ACCOUNT_LOGIN:
          return { ...state, error: action.payload.error, loading: false };
        case ACCOUNT_GET_PROFILE:
          return { ...state, accountError: action.payload.error, account: null, isResolving: false };
        default:
          return state;
      }
    case ACCOUNT_LOGIN:
      return { ...state, loading: true };
    default:
      return state;
  }
};
export default Account;