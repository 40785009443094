import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import PQItemActions from "../../../../Components/PQItemActions";
import { Col, Form, Input, Label, Modal, ModalBody, Row } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import DeleteModal from "../../../../Components/DeleteModal";
import { deleteItem, getSubjects, setSubject } from "../../../../store/actions";
import { MENU_SUBJECT } from "./section";

const Subject = (props) => {
    const { createModal } = props;

    const dispatch = useDispatch();

    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToEdit, setItemToEdit] = useState(null);

    const { subjects, isDeleting, isDeleted, isSubjectSet } = useSelector((state) => ({
        subjects: state.PQ.subjects,
        isDeleting: state.PQ.isDeleting,
        isDeleted: state.PQ.isDeleted,
        isSubjectSet: state.PQ.isSubjectSet,
    }));

    useEffect(() => {
        dispatch(getSubjects());
        if (isSubjectSet) closeModal();
    }, [dispatch, isSubjectSet, isDeleted]);

    useEffect(() => {
        if (isDeleted) setItemToDelete(null);
    }, [isDeleted]);

    const handleDeletePQData = () => {
        dispatch(deleteItem({ type: itemToDelete.type, id: itemToDelete.id }));
    };

    const closeModal = () => {
        setItemToEdit(null);
        props?.closeModal();
        validation.resetForm();
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { name: itemToEdit?.name || "", slug: itemToEdit?.slug || "" },
        validationSchema: Yup.object({
            name: Yup.string().required("Please enter subject name"),
        }),
        onSubmit: (values) => {
            if (itemToEdit) {
                values.subjectID = itemToEdit._id;
            }
            dispatch(setSubject(values));
        },
    });

    return (
        <React.Fragment>
            <DeleteModal
                itemName={itemToDelete?.name}
                show={itemToDelete}
                isDeleting={isDeleting}
                onDeleteClick={() => handleDeletePQData()}
                onCloseClick={() => setItemToDelete(null)}
            />
            <div className="accordion accordion-flush">
                {subjects.map(
                    (pq, index) =>
                        <div
                            key={`subject-${pq._id}`}
                            className="accordion-item d-flex align-items-center d-hover"
                        >
                            <div
                                className={classnames(
                                    "fc-event",
                                    "text-black",
                                )}
                            >
                                {pq?.name}
                            </div>
                            <PQItemActions
                                item={pq}
                                type={MENU_SUBJECT.toLowerCase()}
                                title={pq.name} id={pq._id}
                                onDelete={setItemToDelete}
                                onEdit={setItemToEdit}
                            />
                        </div>

                )}
            </div>
            <Modal
                isOpen={createModal !== '' || itemToEdit}
                toggle={closeModal}
                centered
                backdrop='static'
            >
                <ModalBody className="pt-0 px-0">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col lg={12}>
                                <div className="d-flex pt-2 pb-3 mb-4 bg-soft-primary rounded px-3">
                                    <div className="flex-grow-1">
                                        <h5
                                            className="modal-title text-muted"
                                            id="createMemberLabel"
                                        >
                                            Add Subject
                                        </h5>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex gap-3 align-items-center">
                                            <button
                                                type="button"
                                                className="btn-close btn-close-black"
                                                onClick={() => closeModal()}
                                                id="createMemberBtn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            ></button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <Label htmlFor="subjects" className="form-label mb-2 ms-4">
                                    Subject
                                </Label>
                                <div className="mb-3 mx-3 form-control-sm">
                                    <Input
                                        name="name"
                                        className="form-control border border-1"
                                        value={validation.values.name || ""}
                                        onChange={validation.handleChange}
                                    />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="mb-3 me-4">
                                    <Label
                                        htmlFor="subjects-slug"
                                        className="form-label mb-2 ms-4"
                                    >
                                        URL Slug
                                    </Label>
                                    <div className="d-flex py-1">
                                        <Input
                                            name="slug"
                                            className="form-control ms-4 mx-2"
                                            value={validation.values.slug || ""}
                                            onChange={validation.handleChange}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="hstack gap-2 justify-content-end mt-4 mx-3">
                                    <button
                                        type="button"
                                        className="btn btn-light btn-sm"
                                        onClick={() => closeModal()}
                                    >
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary btn-sm">
                                        Add Subject
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

Subject.propTypes = {
    createModal: PropTypes.string,
    closeModal: PropTypes.func,
};

export default Subject;