import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { accountLogout } from "../../store/actions";

const AccountPopup = (props) => {
  const dispatch = useDispatch();
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);

  const { account } = useSelector((state) => ({
    account: state.Account.account,
  }));

  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  return (
    <React.Fragment>
      {/* <div className="navbar-brand-box" style={{ width: '100%', borderRadius: 0, borderTop:'1px solid #999', borderBottom:'1px solid #ccc' }}>
                <span className="d-flex align-items-center">
                    <span className="text-start ms-xl-2 flex-fill">
                        <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">Adesola Mohiro</span>
                        <span className="d-none d-xl-block ms-1 fs-12 user-name-sub-text">Roware Limited</span>
                    </span>
                    <i className='ri-user-fill ri-xl'></i>
                </span>
            </div> */}
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        direction="up"
      >
        <DropdownToggle className="" style={{ width: "100%", borderRadius: 0 }}>
          <span className="d-flex align-items-center">
            <span className="text-start ms-xl-2 flex-fill">
              <span className="d-none d-lg-inline-block ms-1 fw-medium user-name-text">{`${
                account?.firstName ?? ""
              } ${account?.lastName ?? ""}`}</span>
              <span className="d-none d-lg-block ms-1 fs-12 user-name-sub-text">
                {account?.organizationName ?? ""}
              </span>
            </span>
            <i
              className="ri-user-fill text-white ri-xl"
              style={{ marginLeft: "85px" }}
            ></i>
          </span>
        </DropdownToggle>
        <DropdownMenu style={{ borderRadius: "0px", width: "100%" }}>
          <DropdownItem href="/account">
            <span className="align-middle">Account</span>
          </DropdownItem>
          <DropdownItem
            href="#"
            onClick={() => dispatch(accountLogout(props.router.navigate))}
          >
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default AccountPopup;
