import React from "react";
import { Navigate } from "react-router-dom";

import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import Register2 from "../pages/Authentication/Register2";

// import AdminDashboard from "../pages/Dashboard/admin";
// import UserDashboard from "../pages/Dashboard/admin";

// import APIsAdmin from "../pages/APIs/PQ/admin";
// import APIsUser from "../pages/APIs/PQ/user";

// import PQAdmin from "../pages/APIs/PQ/admin/section";
// import PQUser from "../pages/APIs/PQ/user/pq";
// import UserAccount from "../pages/Account/User";
// import AdminAccount from "../pages/Account/admin";
import JoinTeam from "../pages/Authentication/JoinTeam";
// import Accounts from "../pages/Accounts";

// const vendorRoutes = [
//   { path: "/dashboard", component: <AdminDashboard /> },
//   { path: "/apis", component: <APIsAdmin /> },
//   { path: "/apis/pq", component: <PQAdmin /> },
//   { path: "/accounts", component: <Accounts /> },
//   { path: "/iam-admin", component: <PQAdmin /> },
//   { path: "/account", component: <AdminAccount /> },

//   // this route should be at the end of all other routes
//   // eslint-disable-next-line react/display-name
//   {
//     path: "/",
//     exact: true,
//     component: <Navigate to="/dashboard" />,
//   },
//   { path: "*", component: <Navigate to="/dashboard" /> },
// ];

// const customerRoutes = [
//   { path: "/dashboard", component: <UserDashboard /> },
//   { path: "/apis", component: <APIsUser /> },
//   { path: "/apis/pq", component: <PQUser /> },
//   { path: "/account", component: <UserAccount /> },

//   // this route should be at the end of all other routes
//   // eslint-disable-next-line react/display-name
//   {
//     path: "/",
//     exact: true,
//     component: <Navigate to="/dashboard" />,
//   },
//   { path: "*", component: <Navigate to="/dashboard" /> },
// ];

// const authRoutes = { vendor: vendorRoutes, customer: customerRoutes };

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  // { path: "/register", component: <Register /> },
  { path: "/register", component: <Register2 /> },
  { path: "/join-team", component: <JoinTeam /> },
  { path: "*", component: <Navigate to="/login" /> },
];

export {
  // authRoutes,
  publicRoutes
};