import {
  ACCOUNT_RESPONSE_SUCCESS,
  ACCOUNT_RESPONSE_ERROR,
  ACCOUNT_LOGIN,
  ACCOUNT_GET_PROFILE,
  ACCOUNT_LOGOUT,
  ACCOUNT_SIGN_UP,
  ACCOUNT_RESET_KEY,
  ACCOUNT_JOIN_TEAM
} from "./actionType";

export const accountResponseSuccess = (actionType, data) => ({
  type: ACCOUNT_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const accountResponseError = (actionType, error) => ({
  type: ACCOUNT_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const accountLogin = (data, history) => ({
  type: ACCOUNT_LOGIN,
  payload: { data, history },
});

export const accountSignUp = (data, history) => ({
  type: ACCOUNT_SIGN_UP,
  payload: { data, history },
});

export const accountJoinTeam = (data, history) => ({
  type: ACCOUNT_JOIN_TEAM,
  payload: { data, history },
});

export const accountGetProfile = () => ({
  type: ACCOUNT_GET_PROFILE,
});

export const accountResetKey = () => ({
  type: ACCOUNT_RESET_KEY,
});

export const accountLogout = (history) => ({
  type: ACCOUNT_LOGOUT,
  payload: { history },
});
