import { all, fork } from "redux-saga/effects";
import LayoutSaga from "./layouts/saga";
import AccountSaga from "./account/saga";
import ForgetSaga from "./auth/forgetpwd/saga";

import TeamSaga from "./team/saga";

import DashboardSaga from "./dashboard/saga";
import ApisSaga from "./apis/saga";

import PQSaga from "./pq/saga";

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(ForgetSaga),

    fork(TeamSaga),

    fork(DashboardSaga),
    fork(ApisSaga),

    fork(PQSaga),
  ]);
}
