import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

export const postSignUp = (data) => api.create(url.POST_SIGN_UP, data);
export const postJoinTeam = (data) => api.create(url.POST_JOIN_TEAM, data);
export const postLogin = (data) => api.create(url.POST_LOGIN, data);
export const getProfile = () => api.get(url.GET_PROFILE);
export const logout = () => api.get(url.LOGOUT);

export const getRoles = () => api.get(url.TEAM_ROLES);
export const inviteMember = (data) => api.create(url.TEAM_INVITE_MEMBER, data);

export const getApis = () => api.get(url.GET_APIS);
export const enableApi = (data) => api.create(url.ENABLE_API, data);

export const getExams = (data) => api.get(url.PQ_EXAM, data);
export const getSubjects = (data) => api.get(url.GET_SUBJECTS, data);
export const getSchools = (data) => api.get(url.GET_SCHOOLS, data);
export const getYears = (data) => api.get(url.GET_YEARS, data);
export const getTests = (data) => api.get(url.GET_TESTS, data);
export const getQuestions = (data) => api.get(url.GET_QUESTIONS, data);
export const pqSave = (data) => api.update(url.PUT_PQ_SELECTIONS, data);
export const pqPostExam = (data) => api.create(url.PQ_EXAM, data);
export const pqPostSubject = (data) => api.create(url.PQ_SUBJECT, data);
export const pqPostSchool = (data) => api.create(url.PQ_SCHOOL, data);
export const pqPostYear = (data) => api.create(url.PQ_YEAR, data);
export const pqPostTest = (data) => api.create(url.PQ_TEST, data);
export const pqPostQuestion = (data) => api.create(url.PQ_QUESTION, data);
export const pqDeleteItem = (data) => api.delete(url.PQ_DELETE_ITEM, data);

export const postFakeRegister = (data) =>
  api.create(url.POST_FAKE_REGISTER, data);

export const postFakeLogin = (data) => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = (data) =>
  api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data) =>
  api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data) =>
  api.update(url.POST_EDIT_PROFILE + "/" + data.idx, data);

// Register Method
export const postJwtRegister = (url, data) => {
  return api.create(url, data).catch((err) => {
    var message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

// Login Method
export const postJwtLogin = (data) => api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data) =>
  api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = (data) => api.create(url.SOCIAL_LOGIN, data);

// Dashboard CRM

// Balance Overview
export const getTodayBalanceData = () => api.get(url.GET_TODAYBALANCE_DATA);
export const getLastWeekBalanceData = () =>
  api.get(url.GET_LASTWEEKBALANCE_DATA);
export const getLastMonthBalanceData = () =>
  api.get(url.GET_LASTMONTHBALANCE_DATA);
export const getCurrentYearBalanceData = () =>
  api.get(url.GET_CURRENTYEARBALANCE_DATA);

// Dial Type
export const getTodayDealData = () => api.get(url.GET_TODAYDEAL_DATA);
export const getWeeklyDealData = () => api.get(url.GET_WEEKLYDEAL_DATA);
export const getMonthlyDealData = () => api.get(url.GET_MONTHLYDEAL_DATA);
export const getYearlyDealData = () => api.get(url.GET_YEARLYDEAL_DATA);

// Sales Forecast
export const getOctSalesData = () => api.get(url.GET_OCTSALES_DATA);
export const getNovSalesData = () => api.get(url.GET_NOVSALES_DATA);
export const getDecSalesData = () => api.get(url.GET_DECSALES_DATA);
export const getJanSalesData = () => api.get(url.GET_JANSALES_DATA);
