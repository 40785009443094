import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import { FcGoogle } from "react-icons/fc";
import { FaGithub } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

import { accountLogin } from "../../store/actions";

import withRouter from "../../Components/Common/withRouter";

import heroBg from "../../assets/images/auth-one-bg.png"

const Login = (props) => {
  document.title = "Login | Developer Console";

  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => ({
    loading: state.Account.loading,
    error: state.Account.error,
  }));

  const [userLogin, setUserLogin] = useState([]);
  const [passwordShow, setPasswordShow] = useState(false);

  // useEffect(() => {
  //   if (user && user) {
  //     setUserLogin({
  //       email: user.user.email,
  //       password: user.user.confirm_password,
  //     });
  //   }
  // }, [user]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter email address"),
      password: Yup.string().required("Please enter password"),
    }),
    onSubmit: (values) => {
      dispatch(accountLogin(values, props.router.navigate));
    },
  });

  // useEffect(() => {
  //   if (error) {
  //     setTimeout(() => {
  //       dispatch(resetLoginFlag());
  //     }, 3000);
  //   }
  // }, [dispatch, error]);

  return (
    <React.Fragment>
      <div
        className="overflow-hidden login-main-div"
      >
        <nav className='login__navbar pt-5 pb-5'>
          <Link to='/' className="text-dark ps-5 pb-5">ROWARE LOGO</Link>
        </nav>
        <Row className="">
          <Col lg={6} md={12} className="py-5 my-5 login-col1-query position-fixed" style={{}}>
            <div className="mx-5 hero-img-div">
              <div className="mt-5 mx-5 hero__img-div-2">
                <img src={heroBg} alt="hero banner" className="hero__img" />
              </div>

              <div className="ms-5 login-col1-texts">
                <h4 className="text-center mt-3">Past Questions at your finger tips</h4>
                <p className="text-center mt-3">PQ API allows you to focus on developing your academic applications, platforms and solutions while we take care of the past and practice questions.</p>
                <Link to="" className="d-block text-center text-decoration-underline">Read more</Link>
              </div>

            </div>

          </Col>

          <Col lg={6} md={12} className=" login-col2-query position-absolute" style={{ right: 0, }}>
            <Card className="shadow-none px-5 pt-5 pb-5 mb-0 login-col2-card">
              <div className="mx-5 px-3 login-col2-div">
                <div className="login-col2-section">
                  <h3 className="login-col2-text text-warning mt-4">Log in to your account</h3>
                  <p className="text-muted">
                    Don't have an account yet? <Link to="/register" className="text-decoration-underline">Create an account</Link>
                  </p>
                </div>
                {error ? <Alert color="danger"> {error} </Alert> : null}
                <div className="login-col2-form mt-5">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    action="#"
                  >
                    <div className="mb-4">
                      <Label
                        htmlFor="email"
                        className="form-label fw-normal p-0 m-0 mb-1"
                      >
                        Email
                      </Label>
                      <Input
                        name="email"
                        className="form-control user-form"
                        placeholder="Enter email address"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email &&
                            validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email &&
                        validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-4">

                      <Label
                        className="form-label fw-normal p-0 m-0 mb-1"
                        htmlFor="password-input"
                      >
                        Password
                      </Label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type={passwordShow ? "text" : "password"}
                          className="form-control pe-5"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                              validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                          validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                        <button
                          className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button"
                          id="password-addon"
                          onClick={() => setPasswordShow(!passwordShow)}
                        >
                          <i className="ri-eye-fill align-middle"></i>
                        </button>
                      </div>
                    </div>

                    <div className="mt-5">
                      <Button
                        disabled={error ? null : loading ? true : false}
                        className="btn btn-dark w-100 text-warning"
                        type="submit"
                      >
                        {error ? null : loading ? (
                          <Spinner size="sm" className="me-2">
                            {" "}
                            Loading...{" "}
                          </Spinner>
                        ) : null}
                        Log In
                      </Button>
                    </div>
                  </Form>
                </div>
                <p className="mt-2">This site is protected by hCaptcha and its <Link to="" className="text-decoration-underline">Privacy Policy</Link> and <Link to="" className="text-decoration-underline">Terms of Service apply.</Link></p>
                <div className="my-5 text-center mb-3">
                  {/* <Link
                    to=""
                    className="fw-semibold text-warning text-decoration-underline me-5"
                  >
                    Forgot username?
                  </Link> */}
                  <Link
                    to=""
                    className="fw-semibold text-warning text-decoration-underline"
                  >
                    Reset password
                  </Link>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="" style={{ padding: "0 5px", flex: "1" }}><div className="border-bottom" style={{ height: "50%" }}></div></div>
                  <p className="mt-3">OR</p>
                  <div className="" style={{ padding: "0 5px", flex: "1" }}><div className="border-bottom" style={{ height: "50%" }}></div></div>
                </div>
                <div className="mt-3">
                  <Button
                    color="secondary"
                    className="w-100"
                    outline
                    type="submit"
                  >
                    {error ? null : loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                    <span><FcGoogle size="16px" style={{ marginBottom: '1px' }} /> <p className="ms-1 d-inline text-uppercase fs-13">Log in with google</p></span>
                  </Button>
                </div>
                <div className="mt-4 mb-2">
                  <Button
                    color="secondary"
                    className="w-100"
                    outline
                    type="submit"
                  >
                    {error ? null : loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                    <span><FaGithub size="16px" color="black" style={{ marginBottom: '1px' }} /> <p className="ms-1 d-inline text-uppercase fs-13">Log in with github</p></span>
                  </Button>
                </div>
                <div className="mt-5 text-center mb-4">
                  <Link
                    to=""
                    className="fw-semibold text-warning text-decoration-underline me-5"
                  >
                    Terms
                  </Link>
                  <Link
                    to=""
                    className="fw-semibold text-warning text-decoration-underline me-5"
                  >
                    Privacy
                  </Link>
                  <Link
                    to="/register"
                    className="fw-semibold text-warning text-decoration-underline me-5"
                  >
                    Contact us
                  </Link>
                </div>
                <footer className="mt-3">
                  <div className="text-center text-black">
                    <p className="mb-0 fs-13">
                      &copy;{" "}
                      <span className="fs-12">
                        {new Date().getFullYear()} Roware
                      </span>
                    </p>
                  </div>
                </footer>
              </div>
            </Card>

          </Col>
        </Row>
        {/* </Container> */}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);