import React, { useCallback, useState } from 'react'
import {
    Card,
    CardBody,
    Col,
    Container,
    CardHeader,
    Row,
    Input,
    Label,
    FormFeedback,
    Form,
    Button,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// import { registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const ApiKeys = (props) => {

    document.title = 'Account | Developer Console';

    const { account, isResolving, error } = useSelector(state => ({
        account: state.Account.account,
        isResolving: state.Account.isResolving,
        error: state.Account.error,
    }));


    const [isEdit, setIsEdit] = useState(false);
    const [profile, setProfile] = useState(account);
    const [isCopy, setIsCopy] = useState(false);
    const [isTestCopy, setIsTestCopy] = useState(false);

    const dispatch = useDispatch()

    const handleAccountClick = useCallback((arg) => {
        const account = arg
        setProfile({
            // _id: account._id,
            firstName: profile.firstName,
            lastName: profile.lastName,
            email: profile.email,
            password: profile.password,
            testKey: profile.testKey,
            liveKey: profile.liveKey,
        })

        setIsEdit(true)
    }, [])

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            firstName: profile?.firstName ?? '',
            lastName: profile?.lastName ?? '',
            email: profile?.email ?? '',
            organizationName: profile?.organizationName ?? '',
            password: profile?.password ?? '',
            testKey: profile?.testKey ?? '',
            liveKey: profile?.liveKey ?? '',
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('Please Enter First Name'),
            lastName: Yup.string().required('Please Enter Last Name'),
            email: Yup.string().required('Please Enter a Valid Email Address'),
            password: Yup.string().required('Please Enter Password'),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateAccount = {
                    // _id: account ? account._id : 0,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    password: values.password,
                }
                // update account
                // dispatch(onUpdateAccount(updateAccount));
                validation.resetForm()
            } else {
                const newAccount = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    password: values.password,
                }
            }
            // save new account
            // dispatch(onAddNewAccount(newAccount))
            validation.resetForm()
        },
    })

    const successnotify = () =>
        toast('Copied successfully!', {
            position: 'top-right',
            hideProgressBar: true,
            closeOnClick: false,
            className: 'bg-success text-white text-left w-75',
        });

    const onResetKey = () => {
        alert();
    }

    return (
        <div className="">
            <ToastContainer />
            <Container fluid>
                <Row>
                    <Col lg={8}>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                        >
                            <Card className='shadow-none'>
                                <CardBody>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-1">
                                                <Label
                                                    className="form-label fw-normal p-0 m-0 mb-1"
                                                    htmlFor="test-key"
                                                >
                                                    Test Key
                                                </Label>
                                                <div className="d-flex">
                                                    <Input
                                                        type="text"
                                                        className="form-control-sm"
                                                        placeholder="XXXXXXXXXXXXXX"
                                                        id="test-key"
                                                        name="testKey"
                                                        value={validation.values.testKey || ''}
                                                        disabled
                                                    />
                                                    <i
                                                        onClick={() =>
                                                            navigator.clipboard
                                                                .writeText(validation.values.liveKey)
                                                                .then(() => {
                                                                    successnotify()
                                                                    setTimeout(() => {
                                                                        setIsCopy(false)
                                                                    }, 3000)
                                                                })
                                                        }
                                                        className="ri-file-copy-line"
                                                    ></i>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label
                                                    className="form-label fw-normal p-0 m-0 mb-1"
                                                    htmlFor="live-key"
                                                >
                                                    Live Key
                                                </Label>
                                                <div className="d-flex">
                                                    <Input
                                                        type="text"
                                                        className="form-control-sm"
                                                        placeholder="XXXXXXXXXXXXXX"
                                                        id="live-key"
                                                        name="liveKey"
                                                        value={validation.values.liveKey || ''}
                                                        disabled
                                                    />
                                                    <i
                                                        onClick={() =>
                                                            navigator.clipboard
                                                                .writeText(validation.values.liveKey)
                                                                .then(() => {
                                                                    successnotify()
                                                                    setTimeout(() => {
                                                                        setIsCopy(false)
                                                                    }, 3000)
                                                                })
                                                        }
                                                        className="ri-file-copy-line"
                                                    ></i>
                                                </div>
                                            </div>

                                            <div className="">
                                                <Button className="btn btn-soft-dark btn-sm" onClick={onResetKey}>
                                                    Reset Key
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ApiKeys;