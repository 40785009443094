import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table, UncontrolledDropdown } from 'reactstrap';
import { inviteMember, listRoles } from '../../../store/actions';
import { useFormik } from 'formik';

const DocumentsTab = () => {

    const dispatch = useDispatch();

    const { roles, isInviting, isInvited } = useSelector(state => ({
        roles: state.Team.roles,
        isInviting: state.Team.isInviting,
        isInvited: state.Team.isInvited,
    }));
    console.log('isInvited', isInvited);

    const [modal, setModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');

    const toggle = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    };

    useEffect(() => {
        dispatch(listRoles());
        if (isInvited) dismissModal();
    }, [dispatch, isInvited]);

    const dismissModal = () => {
        toggle();
        validation.resetForm();
    }

    const validation = useFormik({
        initialValues: { email: "" },
        // validationSchema: Yup.object({
        //     email: Yup.string().required("Please enter email address"),
        //     role: Yup.string().required("Please select a role"),
        // }),
        onSubmit: (values) => {
            const p = { ...values, role: selectedRole };
            dispatch(inviteMember(p));
        },
    });

    return (
        <React.Fragment>
            <Card className='shadow-none'>
                <CardBody>
                    <Row className="g-4 mb-4">
                        <div className="col-sm">

                        </div>
                        <div className="col-sm-auto">
                            <div>
                                <button type="button" className="btn btn-success btn-sm"
                                    onClick={toggle}
                                ><i className="ri-add-line me-1 align-bottom"></i> Invite Member</button>
                            </div>
                        </div>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <div className="table-responsive table-card">
                                <Table className="table-borderless align-middle mb-0">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email Address</th>
                                            <th scope="col">Role</th>
                                            <th scope="col">2FA Status</th>
                                            <th scope="col">Last Login</th>
                                            <th scope="col" style={{ width: "120px" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* <tr>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm">
                                                        <div className="avatar-title bg-light text-secondary rounded fs-24">
                                                            <i className="ri-folder-zip-line"></i>
                                                        </div>
                                                    </div>
                                                    <div className="ms-3 flex-grow-1">
                                                        <h5 className="fs-14 mb-0"><Link to="#" className="text-dark">Artboard-documents.zip</Link></h5>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Zip File</td>
                                            <td>4.57 MB</td>
                                            <td>12 Dec 2021</td>
                                            <td>12 Dec 2021</td>
                                            <td>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="a" href="#" className="btn btn-soft-secondary btn-sm btn-icon">
                                                        <i className="ri-more-fill"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end">
                                                        <li><DropdownItem><i className="ri-eye-fill me-2 align-bottom text-muted"></i>View</DropdownItem></li>
                                                        <li><DropdownItem><i className="ri-download-2-fill me-2 align-bottom text-muted"></i>Download</DropdownItem></li>
                                                        <li className="dropdown-divider"></li>
                                                        <li><DropdownItem><i className="ri-delete-bin-5-fill me-2 align-bottom text-muted"></i>Delete</DropdownItem></li>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </Table>
                            </div>
                            {/* <div className="text-center mt-3">
                                <Link to="#" className="text-success "><i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load more </Link>
                            </div> */}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Modal isOpen={modal} toggle={toggle} modalClassName="flip" centered style={{ width: '380px' }}>
                <ModalHeader className="p-3" toggle={toggle}>
                    <span className='fw-normal fs-15'>Invite Team Member</span>
                </ModalHeader>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
                    action="#">
                    <ModalBody>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label fw-normal p-0 m-0 mb-1">Email</label>
                            <input type="email" className="form-control" id="email" name='email'
                                placeholder="Enter email address" required
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                            // invalid={
                            //     validation.touched.email &&
                            //         validation.errors.email
                            //         ? true
                            //         : false
                            // }
                            />
                            {validation.touched.email &&
                                validation.errors.email ? (
                                <div className="invalid-feedback">
                                    Please enter an email address.
                                </div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="role" className="form-label fw-normal p-0 m-0 mb-1">Role</label>
                            <select className="form-select" id="role" data-choices
                                aria-label="Default select example" required
                                onChange={(e) => setSelectedRole(e.target.value)}>
                                <option value=''>Select a role</option>
                                {roles.map((i, x) => <option key={`role-${x}`} value={i}>{i}</option>)}
                            </select>
                            <div className="invalid-feedback">
                                Please select a role.
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="button"
                            className='btn-sm btn-light'
                            onClick={() => {
                                setModal(false);
                            }}
                        >
                            Close
                        </Button>
                        {isInviting ? <Button type="button" color="success" className='btn-sm'>
                            <span className="d-flex align-items-center">
                                <Spinner size="sm" type="grow" className="flex-shrink-0" />
                                <span className="flex-grow-1 ms-2">
                                    Inviting...
                                </span>
                            </span>
                        </Button> : <Button type="submit" color="success" className='btn-sm'>
                            <i className="ri-add-line align-bottom me-1"></i> Invite Member
                        </Button>}
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default DocumentsTab;