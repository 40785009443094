import {
  TEAM_LIST_ROLES,
  TEAM_INVITE_MEMBER,
  TEAM_RESPONSE_SUCCESS,
  TEAM_RESPONSE_ERROR,
} from "./actionType";

const INIT_STATE = {
  roles: [],
  isInviting: false,
  isInvited: false,
};

const Team = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TEAM_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case TEAM_LIST_ROLES:
          return { ...state, roles: action.payload.data, isInviting: false, isInvited: false };
        case TEAM_INVITE_MEMBER:
          return { ...state, isInviting: false, isInvited: true };
        default:
          return state;
      }
    case TEAM_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case TEAM_INVITE_MEMBER:
          return { ...state, error: action.payload.error, isInviting: false, isInvited: false };
        default:
          return state;
      }
    case TEAM_INVITE_MEMBER:
      return { ...state, isInviting: true };
    default:
      return state;
  }
};
export default Team;