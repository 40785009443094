import { combineReducers } from "redux";

import Layout from "./layouts/reducer";
import Account from "./account/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";

import Team from "./team/reducer";

import Dashboard from "./dashboard/reducer";
import Apis from "./apis/reducer";

import PQ from "./pq/reducer";

const rootReducer = combineReducers({
    Layout,
    Account,
    ForgetPassword,

    Team,

    Dashboard,
    Apis,

    PQ,
});

export default rootReducer;