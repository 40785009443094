import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import AdminDashboard from "../pages/Dashboard/admin";
import UserDashboard from "../pages/Dashboard/admin";

import APIsAdmin from "../pages/APIs/PQ/admin";
import APIsUser from "../pages/APIs/PQ/user";

import PQAdmin from "../pages/APIs/PQ/admin/section";
import PQUser from "../pages/APIs/PQ/user/pq";
import UserAccount from "../pages/Account/User";
import AdminAccount from "../pages/Account/Admin";
import Accounts from "../pages/Accounts";

import VerticalLayout from "../Layouts/index";
// import { authRoutes } from "./allRoutes";
import { AuthProtected } from './AuthProtected';
import { useSelector } from 'react-redux';

const DynamicRoutes = (props) => {

    const { account, isResolving } = useSelector(state => ({
        account: state.Account.account,
        isResolving: state.Account.isResolving,
    }));

    if (!account && isResolving) {
        return <>Loading...</>
    } else if (!account && !isResolving) {
        return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    }

    const vendorRoutes = [];
    vendorRoutes.push({ path: "/dashboard", component: <AdminDashboard /> });
    vendorRoutes.push({ path: "/apis", component: <APIsAdmin /> });
    vendorRoutes.push({ path: "/apis/pq", component: <PQAdmin /> });
    vendorRoutes.push({ path: "/accounts", component: <Accounts /> });
    vendorRoutes.push({ path: "/iam-admin", component: <PQAdmin /> });
    vendorRoutes.push({ path: "/account", component: <AdminAccount /> });
    vendorRoutes.push({ path: "/", exact: true, component: <Navigate to="/dashboard" /> });
    vendorRoutes.push({ path: "*", component: <Navigate to="/dashboard" /> });

    const customerRoutes = [
        { path: "/dashboard", component: <UserDashboard /> },
        { path: "/apis", component: <APIsUser /> },
        { path: "/apis/pq", component: <PQUser /> },
        { path: "/account", component: <UserAccount /> },
        {
            path: "/",
            exact: true,
            component: <Navigate to="/dashboard" />,
        },
        { path: "*", component: <Navigate to="/dashboard" /> },
    ];

    const authRoutes = { vendor: vendorRoutes, customer: customerRoutes };

    return (
        <Routes>
            <Route>
                {authRoutes[account?.type]?.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            <AuthProtected>
                                <VerticalLayout>{route.component}</VerticalLayout>
                            </AuthProtected>}
                        key={idx}
                        exact={true}
                    />
                ))}
            </Route>
        </Routes>
    );
};

export default DynamicRoutes;