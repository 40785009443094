import {
  APIS_LIST,
  APIS_LIST_SUCCESSFUL,
  APIS_LIST_FAILED,
  API_ENABLE
} from "./actionTypes";

const initialState = {
  list: [],
  loading: false,
  success: false,
  error: false,
  apiEnabled: null,
};

const APIs = (state = initialState, action) => {
  switch (action.type) {
    case APIS_LIST:
      state = { ...state, loading: true };
      break;
    case APIS_LIST_SUCCESSFUL:
      // state = { ...state, loading: false, list: action.payload.data, success: true };
      // break;
      switch (action.payload.actionType) {
        case APIS_LIST:
          state = { ...state, loading: false, list: action.payload.data, success: true, apiEnabled: null };
          break
        case API_ENABLE:
          state = { ...state, apiEnabled: true };
          break
        default:
          state = { ...state };
          break;
      }
      break;
    case APIS_LIST_FAILED:
      state = { ...state, user: null, loading: false, message: action.payload.msg, error: true };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default APIs;
