import React, { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { accountSignUp } from "../../store/actions";

// import heroBg from "../../images/auth-one-bg.png"
import heroBg from "../../assets/images/auth-one-bg.png"

const Register = (props) => {
  document.title = "Get Started | Developer Console";

  const history = useNavigate();
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      organizationName: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      firstName: Yup.string().required("Please Enter Your First Name"),
      lastName: Yup.string().required("Please Enter Your Last Name"),
      organizationName: Yup.string().required(
        "Please Enter Your Organization Name"
      ),
      password: Yup.string().required("Please Enter Your Password"),
      // confirm_password: Yup.string().when("password", {
      //   is: (val) => (val && val.length > 0 ? true : false),
      //   then: Yup.string().oneOf(
      //     [Yup.ref("password")],
      //     "Confirm Password Isn't Match"
      //   ),
      // }),
    }),
    onSubmit: (values) => {
      dispatch(accountSignUp(values, history));
    },
  });

  const { error, registrationError, success } = useSelector((state) => ({
    registrationError: state.Account.registrationError,
    success: state.Account.success,
    error: state.Account.error,
  }));

  // useEffect(() => {
  //     dispatch(apiError(""));
  // }, [dispatch]);

  useEffect(() => {
    if (success) {
      setTimeout(() => history("/login"), 3000);
    }

    // setTimeout(() => {
    //   dispatch(resetSignUpFlag());
    // }, 3000);
  }, [dispatch, success, error, history]);

  return (
    <React.Fragment>
      <div
        className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100"
        style={{ backgroundColor: "#FFF" }}
      >
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden shadow-none">
                  <Row className="g-5">
                    <Col lg={6}>
                      {/* <div className="auth-one-bg h-100"></div> */}
                        <div className="hero__img-div mt-5">
                          <img src={heroBg} alt="hero banner" className="hero__img" />
                        </div>
                    </Col>

                    <Col md={8} lg={6}>
                      <div className="px-4 pb-4 border pt-5">
                        <div>
                          <h5 className="text-warning">Create New Account</h5>
                        </div>
                        <div className="p-2 mt-4">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                            className="needs-validation"
                            action="#"
                          >
                            {success && success ? (
                              <>
                                {toast("Your Redirect To Login Page...", {
                                  position: "top-right",
                                  hideProgressBar: false,
                                  className: "bg-success text-white",
                                  progress: undefined,
                                  toastId: "",
                                })}
                                <ToastContainer autoClose={2000} limit={1} />
                                <Alert color="success">
                                  Register User Successfully and Your Redirect
                                  To Login Page...
                                </Alert>
                              </>
                            ) : null}

                            {error && error ? (
                              <Alert color="danger">
                                <div>
                                  
                                  Email has been Register Before, Please Use
                                  Another Email Address...{" "}
                                </div>
                              </Alert>
                            ) : null}

                            <div className="mb-3">
                              <Label
                                htmlFor="firstName"
                                className="form-label fw-normal p-0 m-0 mb-1"
                              >
                                First Name{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="firstName"
                                type="text"
                                placeholder="Enter First Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstName || ""}
                                invalid={
                                  validation.touched.firstName &&
                                  validation.errors.firstName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.firstName &&
                              validation.errors.firstName ? (
                                <FormFeedback type="invalid">
                                  <div>{validation.errors.firstName}</div>
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label
                                htmlFor="lastName"
                                className="form-label fw-normal p-0 m-0 mb-1"
                              >
                                Last Name <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="lastName"
                                type="text"
                                placeholder="Enter Last Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastName || ""}
                                invalid={
                                  validation.touched.lastName &&
                                  validation.errors.lastName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.lastName &&
                              validation.errors.lastName ? (
                                <FormFeedback type="invalid">
                                  <div>{validation.errors.lastName}</div>
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label
                                htmlFor="useremail"
                                className="form-label fw-normal p-0 m-0 mb-1"
                              >
                                Email <span className="text-danger">*</span>
                              </Label>
                              <Input
                                id="email"
                                name="email"
                                className="form-control"
                                placeholder="Enter email address"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  <div>{validation.errors.email}</div>
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label
                                htmlFor="organizationName"
                                className="form-label fw-normal p-0 m-0 mb-1"
                              >
                                Organization Name{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="organizationName"
                                type="text"
                                placeholder="Enter Organization Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.organizationName || ""}
                                invalid={
                                  validation.touched.organizationName &&
                                  validation.errors.organizationName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.organizationName &&
                              validation.errors.organizationName ? (
                                <FormFeedback type="invalid">
                                  <div>
                                    {validation.errors.organizationName}
                                  </div>
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label
                                htmlFor="userpassword"
                                className="form-label fw-normal p-0 m-0 mb-1"
                              >
                                Password <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="password"
                                type="password"
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  <div>{validation.errors.password}</div>
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-2">
                              <Label
                                htmlFor="confirmPassword"
                                className="form-label fw-normal p-0 m-0 mb-1"
                              >
                                Confirm Password{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="confirm_password"
                                type="password"
                                placeholder="Confirm Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.confirm_password || ""}
                                invalid={
                                  validation.touched.confirm_password &&
                                  validation.errors.confirm_password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.confirm_password &&
                              validation.errors.confirm_password ? (
                                <FormFeedback type="invalid">
                                  <div>
                                    {validation.errors.confirm_password}
                                  </div>
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mt-4">
                              <button
                                className="btn btn-dark w-100 text-warning"
                                type="submit"
                              >
                                Sign Up
                              </button>
                            </div>
                          </Form>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            Already have an account ?{" "}
                            <Link
                              to="/login"
                              className="fw-semibold text-warning text-decoration-underline"
                            >
                              {" "}
                              Sign In{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <footer className="footer">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center text-black">
                  <p className="mb-0 fs-13">
                    &copy;{" "}
                    <span className="fs-12">
                      {new Date().getFullYear()} Roware
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default Register;
